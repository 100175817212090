<template>
  <div class="RefundBillCreate" v-loading="loading">
    <el-row class="border_bottom">
      <el-col :span="9" class="pad_15 border_right">
        <el-autocomplete popper-class="customer-autocomplete" prefix-icon="el-icon-user-solid" v-model="customerName" style="width: 100%" size="small" placeholder="请输入客户姓名、手机号、编号查找" :fetch-suggestions="saleCustomerData" @select="handleCustomerSelect" :disabled="customerID != null" :trigger-on-focus="false" :hide-loading="true" :highlight-first-item="true" :select-when-unmatched="true">
          <template slot="append">
            <el-button icon="el-icon-delete" @click="removeCustomer"></el-button>
          </template>
          <template slot-scope="{ item }">
            <div class="name">
              {{ item.Name }}
              <el-tag size="mini" v-if="item.CustomerLevelName">{{
                item.CustomerLevelName
              }}</el-tag>
            </div>
            <div class="info">手机号：{{ item.PhoneNumber }}</div>
            <span class="info" v-if="item.Code">客户编号：{{ item.Code }}</span>
          </template>
        </el-autocomplete>
      </el-col>
      <el-col :span="15" class="pad_15">
        <el-col :span="12" style="white-space: nowrap">
          <el-form :inline="true" size="small">
            <el-form-item style="margin-bottom: 0px; height: 32px" label="补单">
              <span slot="label">
                退款时间
                <el-popover placement="bottom-start" trigger="hover">
                  <p>1.退款时间多用于退款历史订单。</p>
                  <p>2.使用退款时间后该笔订单的相关营收将计入补录的时间。</p>
                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                </el-popover>
              </span>
              <el-switch v-model="IsSupplement"></el-switch>
            </el-form-item>
            <el-form-item style="margin-bottom: 0px; height: 32px" v-show="IsSupplement">
              <el-date-picker v-model="BillDate" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" default-time="9:30:00"></el-date-picker>
            </el-form-item>
          </el-form>
        </el-col>
      </el-col>
    </el-row>

    <el-row class="border_bottom refundBill_content">
      <el-col :span="9" class="project_left">
        <div class="pad_15">
          <el-input placeholder="请输入商品名称、别名关键字，按回车搜索" v-model="goodsName" size="small" clearable @keyup.enter.native="searchGoodsClick" @clear="clearClick">
            <i slot="suffix" @click="searchGoodsClick" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>

        <el-tabs class="treatGoods" v-model="tabPane" @tab-click="handleClick">
          <el-tab-pane v-if="projectList.length > 0" label="项目" name="0">
            <el-scrollbar class="el-scrollbar_height">
              <el-card class="marbm_10 marlt_10 marrt_10 cursor_pointer " :class="index == 0 ? 'martp_10' : ''" :body-style="customCardBody_style(item)" :shadow="customShadow(item)" v-for="(item, index) in projectList" :key="index" @click.native="selectItem(item, 0)">
                <div slot="header">
                  <span>{{ item.Name }}</span>
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                  <el-tag v-if="item.RefundBalance > 0" size="mini" class="marlt_5" type="danger">退款处理中</el-tag>
                </div>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">有效次数：</span>
                      <span>{{ item.ValidBalance }}</span>
                      <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                        <p>
                          有效次数=
                          剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                        </p>
                        <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">剩余数量：</span>
                      <span>{{ item.Balance }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">退款中数量：</span>
                      <span>{{ item.RefundBalance }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">购买价格：</span>
                      <span>¥ {{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">购买数量：</span>
                      <span>{{ item.Quantity }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">参考单价：</span>
                      <span>¥ {{ item.Amount | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">欠款金额：</span>
                      <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">购买日期：</span>
                      <span>{{ item.BuyDate }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">有效余额：</span>
                      <span>¥ {{ item.PerformanceBalanceAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane v-if="savingCardList.length > 0" label="储值卡" name="1">
            <el-scrollbar class="el-scrollbar_height el_scrollbar_project">
              <el-card class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index == 0 ? 'martp_10' : ''" :body-style="(item.RefundAmount>0 && item.ValidBalance == 0)?{padding: '0px' ,'background-color': '#F5F7FA' }:{ padding: '0px'}" :shadow="(item.RefundAmount>0 && item.ValidBalance == 0)?'never':'hover'" v-for="(item, index) in savingCardList" :key="index" @click.native="selectItem(item, 2)">
                <div slot="header">
                  <span>{{ item.Name }}</span>
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.RefundAmount>0 && item.ValidBalance == 0" size="mini" class="marlt_5" type="danger">退款处理中</el-tag>
                </div>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">有效余额：</span>
                      <span>¥ {{ item.ValidBalance | NumFormat }}</span>
                      <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                        <p>1.有效金额= 剩余数量-退款中金额-欠款金额；</p>
                        <p>
                          2.注意：如果有赠额的情况，赠额可用金额也跟退款金额和欠款金额有关；
                        </p>
                        <p>
                          3.比如：充值100送100，欠款50，则可用本金为50，可用赠额为50，可用余额为100。
                        </p>
                        <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">剩余金额：</span>
                      <span>¥ {{ item.TotalBalance | NumFormat }}</span>
                      <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                        <p>剩余金额= 剩余本金+剩余赠额</p>
                        <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">退款中金额：</span>
                      <span>¥ {{ item.RefundAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">购买金额：</span>
                    <span>{{ item.TotalAmount | NumFormat }}</span>
                  </div>
                </el-col>
                <el-row class="border_bottom">
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">剩余本金：</span>
                      <span>¥ {{ item.Balance | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">剩余赠额：</span>
                      <span>¥ {{ item.LargessBalance | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">欠款金额：</span>
                      <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">购买日期：</span>
                      <span>{{ item.BuyDate }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">有效期：</span>
                      <span>{{ item.ValidDayName }}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane v-if="timeCardList.length > 0" label="时效卡" name="2">
            <el-scrollbar class="el-scrollbar_height el_scrollbar_project">
              <el-card class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index == 0 ? 'martp_10' : ''" :body-style="item.IsRefund?{padding: '0px' ,'background-color': '#F5F7FA' }:{ padding: '0px'}" :shadow="item.IsRefund?'never':'hover'" v-for="(item, index) in timeCardList" :key="index" @click.native="selectItem(item, 4)">
                <div slot="header">
                  <span>{{ item.Name }}</span>
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                  <el-tag v-if="item.IsRefund" size="mini" class="marlt_5" type="danger">退款处理中</el-tag>
                </div>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">周期内消耗次数：</span>
                      <span>{{ item.ConsumeCycleAmount }}</span>
                      <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                        <p>
                          当“周期内消耗次数”超过“消耗周期限制”，则不能使用。
                        </p>
                        <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">消耗周期限制：</span>
                      <span>{{
                        item.ConsumeCycle == 0 || item.CycleLimitAmount == 0
                          ? "不限制"
                          : item.CycleLimitAmount +
                            "(次)/" +
                            item.ConsumeCycle +
                            "(天)"
                      }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">累计耗用次数：</span>
                      <span>{{ item.ConsumeAmount }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">购买金额：</span>
                      <span v-if="item.IsLargess">¥ 0.00</span>
                      <span v-else>¥ {{ item.BuyTotalAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">业绩提成次数：</span>
                      <span>{{ item.PerformanceBalance }}</span>
                      <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                        <p>用于确认员工消耗业绩。</p>
                        <p>
                          比如：购买金额为1000，业绩提成次数为10，则每次使用时效卡耗做项目，则1~10次时，员工业绩为100，11次以后（包含第11次），员工业绩为0。
                        </p>
                        <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">参考单价：</span>
                      <span>¥ {{ item.Amount | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">欠款金额：</span>
                      <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">购买日期：</span>
                      <span>{{ item.BuyDate }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">有效期：</span>
                      <span>{{ item.ValidDayName }}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-scrollbar>
          </el-tab-pane>

          <el-tab-pane v-if="generalCardList.length > 0" label="通用次卡" name="3">
            <el-scrollbar class="el-scrollbar_height el_scrollbar_project">
              <el-card class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index == 0 ? 'martp_10' : ''" :body-style="item.RefundAmount>0?{padding: '0px' ,'background-color': '#F5F7FA' }:{ padding: '0px'}" :shadow="item.RefundAmount>0?'never':'hover'" v-for="(item, index) in generalCardList" :key="index" @click.native="selectItem(item, 3)">
                <div slot="header">
                  <span>{{ item.Name }}</span>
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                  <el-tag v-if="item.RefundAmount > 0" size="mini" class="marlt_5" type="danger">退款处理中</el-tag>
                </div>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">有效次数：</span>
                      <span>{{ item.ValidBalance }}</span>
                      <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                        <p>
                          有效次数=
                          剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                        </p>
                        <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">剩余次数：</span>
                      <span>{{ item.Balance }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">退款中数量：</span>
                      <span>{{ item.RefundAmount }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">购买金额：</span>
                      <span v-if="item.IsLargess">¥ 0.00</span>
                      <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">卡总次数：</span>
                      <span>{{ item.CardTimes }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">参考单价：</span>
                      <span>¥ {{ item.Amount | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">欠款金额：</span>
                      <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">购买日期：</span>
                      <span>{{ item.BuyDate }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">有效期：</span>
                      <span>{{ item.ValidDayName }}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-scrollbar>
          </el-tab-pane>

          <el-tab-pane v-if="packageCardList.length > 0" label="套餐卡" name="4">
            <el-scrollbar class="el-scrollbar_height ">
              <div v-for="(item, index) in packageCardList" :key="index" class="pad_10_15 cursor_pointer">
                <!-- IsFullRefund -->
                <el-card :body-style="item.IsFullRefund?{padding: '0px' ,'background-color': '#F5F7FA' }:{ padding: '0px'}" :shadow="item.IsFullRefund?'never':'hover'" @click.native="selectAllPackageItem(item)">
                  <div slot="header" class="position_relative">
                    <span>{{ item.Name }}</span>
                    <span v-if="item.Alias">({{ item.Alias }})</span>
                    <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                    <el-tag v-if="item.IsFullRefund" size="mini" class="marlt_5" type="danger">退款处理中</el-tag>
                    <el-button @click.stop="clickPackageCagegory(item, index)" class="position_absolute font_13" style="right:10px;top:-10px" type="text" slot="reference">查看明细
                      <i class="el-icon-view el-icon--right"></i>
                    </el-button>
                    <!-- 
                          <el-popover placement="right" width="400" trigger="click" @show="showPackageDetail">
                            <el-col :span="20" class="project" v-loading="packageDetailLoading" >
                              <div class="producct_list">
                                
                              </div>
                            </el-col>

                            <el-button @click.stop="clickPackageCagegory(item, index)" class="position_absolute font_13" style="right:10px;top:-10px" type="text" slot="reference">明细</el-button> -->
                    <!-- </el-popover> -->
                  </div>
                  <el-row class="border_bottom">
                    <el-col :span="12" class="border_right">
                      <div class="goods-item">
                        <span class="goods-lable">购买价格：</span>
                        <span>¥ {{ item.TotalAmount | NumFormat }}</span>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="goods-item">
                        <span class="goods-lable">欠款金额：</span>
                        <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12" class="border_right">
                      <div class="goods-item">
                        <span class="goods-lable">购买日期：</span>
                        <span>{{ item.BuyDate }}</span>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="goods-item">
                        <span class="goods-lable">有效期：</span>
                        <span>{{ item.ValidDayName }}</span>
                      </div>
                    </el-col>
                  </el-row>
                </el-card>
              </div>
            </el-scrollbar>
          </el-tab-pane>

          <el-tab-pane v-if="productList.length > 0" label="产品" name="5">
            <el-scrollbar class="el-scrollbar_height el_scrollbar_project">
              <el-card class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index == 0 ? 'martp_10' : ''" :body-style="customCardBody_style(item)" :shadow="customShadow(item)" v-for="(item, index) in productList" :key="index" @click.native="selectItem(item, 5)">
                <div slot="header">
                  <span>{{ item.Name }}</span>
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                  <el-tag v-if="item.RefundBalance > 0" size="mini" class="marlt_5" type="danger">退款处理中</el-tag>
                </div>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">有效数量：</span>
                      <span>{{ item.ValidBalance }}</span>
                      <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                        <p>
                          有效数量=
                          剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                        </p>
                        <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">剩余数量：</span>
                      <span>{{ item.Balance }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">退款中数量：</span>
                      <span>{{ item.RefundBalance }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">购买价格：</span>
                      <span v-if="item.IsLargess">¥ 0.00</span>
                      <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">购买数量：</span>
                      <span>{{ item.Quantity }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">参考单价：</span>
                      <span>¥ {{ item.Amount | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">欠款金额：</span>
                      <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">购买日期：</span>
                      <span>{{ item.BuyDate }}</span>
                    </div>
                  </el-col>

                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">有效余额：</span>
                      <span>¥ {{ item.PerformanceBalanceAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-scrollbar>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="15" class="project_right">
        <el-container style="height: 100%" direction="vertaical">
          <el-main>
            <el-scrollbar class="el-scrollbar_height" :class="{ back_f2: selectProject.length > 0 }">
              <!--项目-->
              <div v-if="selectProject.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="7">项目</el-col>
                    <el-col :span="6">数量</el-col>
                    <el-col :span="6">退款金额</el-col>
                    <el-col :span="5">操作</el-col>
                  </el-col>
                </el-row>
                <el-row class="border_bottom items" v-for="(item, index) in selectProject" :key="index">
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="7">
                        <div>
                          <span>{{ item.Name }}</span>
                          <span v-if="item.Alias">({{ item.Alias }})</span>
                          <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                        </div>
                      </el-col>
                      <el-col :span="6">
                        <el-input-number :min="1" size="mini" style="width: 100px" :max="item.allRefund?item.Balance:item.ValidBalance" v-model="item.number" @change="numberChange(item, 1)" :disabled="item.allRefund"></el-input-number>
                      </el-col>
                      <el-col :span="6">
                        <el-input v-model="item.RefundPrice" :disabled="item.IsLargess " :min="0" placeholder size="mini" type="number" v-enter-number2 style="width: 50%" @input="arrearChange(item, 1)"></el-input>
                      </el-col>
                      <el-col :span="3">
                        <el-checkbox v-model="item.allRefund" @change="(evn)=>allRefundChangeClick(evn,item)">全退</el-checkbox>
                      </el-col>
                      <el-col :span="2">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(1, index, item)"></el-button>
                      </el-col>
                    </el-col>
                    <el-col :span="24" style="margin-top: 4px">
                      <el-col :span="7">
                        <div class="color_red">
                          ¥ {{ item.Price | NumFormat }}
                        </div>
                      </el-col>
                      <el-col :span="6">
                        <span class="color_gray font_12">剩余次数:{{ item.allRefund?item.Balance:item.ValidBalance}}次</span>
                      </el-col>
                      <el-col :span="6">
                        <span v-if="item.PricePreferentialAmount" class="font_12 color_gray">
                          手动改价：- {{item.PricePreferentialAmount | NumFormat}}</span>
                      </el-col>
                    </el-col>
                  </el-col>
                  <el-col :span="24" class="pad_10 padbm_0">
                    <el-row class="cursor_pointer" v-for="(handler, pIndex) in item.handleTypeList" :key="pIndex" @click.native="
                        employeeHandleClick(1, selectProject, item, index)
                      " type="flex" align="top">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName} [${employee.EmployeeID}]`">
                            <el-input class="employee_num" v-model="employee.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="
                                handlerPercentChange(handler.Employee, employee)
                              ">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5" style="
                                font-size: 16px;
                                color: rgb(144,147,153);
                                vertical-align: middle;
                              " v-on:click.stop="
                                removeHandleClick(handler, handleIndex)
                              "></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>

              <!--产品-->
              <div v-if="selectProduct.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="7">产品</el-col>
                    <el-col :span="6">数量</el-col>
                    <el-col :span="6">退款</el-col>
                    <el-col :span="5">操作</el-col>
                  </el-col>
                </el-row>
                <el-row class="border_bottom items" v-for="(item, index) in selectProduct" :key="index">
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="7">
                        <div>
                          <span>{{ item.Name }}</span>
                          <span v-if="item.Alias">({{ item.Alias }})</span>
                          <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                        </div>
                      </el-col>
                      <el-col :span="6">
                        <el-input-number :min="1" :disabled="item.allRefund" size="mini" style="width: 100px" :max="item.allRefund?item.Balance:item.ValidBalance" v-model="item.number" @change="numberChange(item, 1)"></el-input-number>
                      </el-col>
                      <el-col :span="6">
                        <el-input v-model="item.RefundPrice" :disabled="item.IsLargess " :min="0" placeholder size="mini" type="number" v-enter-number2 style="width: 50%" @input="arrearChange(item, 1)"></el-input>
                      </el-col>
                      <el-col :span="3">
                        <el-checkbox v-model="item.allRefund" @change="(evn)=>allRefundChangeClick(evn,item)">全退</el-checkbox>
                      </el-col>
                      <el-col :span="2">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(6, index, item)"></el-button>
                      </el-col>
                    </el-col>
                    <el-col :span="24" style="margin-top: 4px">
                      <el-col :span="7">
                        <div class="color_red">
                          ¥ {{ item.Price | NumFormat }}
                        </div>
                      </el-col>
                      <el-col :span="6">
                        <span class="color_gray font_12">有效次数:{{ item.allRefund?item.Balance:item.ValidBalance }}次</span>
                      </el-col>
                      <el-col :span="6">
                        <span v-if="item.PricePreferentialAmount" class="font_12 color_gray">
                          手动改价：- {{item.PricePreferentialAmount | NumFormat}}</span>
                      </el-col>

                    </el-col>
                  </el-col>

                  <el-col :span="24" class="pad_10 padbm_0">
                    <el-row class="cursor_pointer" v-for="(handler, pIndex) in item.handleTypeList" :key="pIndex" @click.native="
                        employeeHandleClick(6, selectProduct, item, index)
                      ">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName} [${employee.EmployeeID}] `">
                            <el-input class="employee_num" v-model="employee.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="
                                handlerPercentChange(handler.Employee, employee)
                              ">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5" style="
                                font-size: 16px;
                                color: rgb(144,147,153);
                                vertical-align: middle;
                              " v-on:click.stop="
                                removeHandleClick(handler, handleIndex)
                              "></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>

              <!--储值卡-->
              <div v-if="selectSavingCard.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="8">储值卡</el-col>
                    <el-col :span="7">数量</el-col>
                    <el-col :span="7">退款金额/退赠送金额</el-col>
                    <el-col :span="2">操作</el-col>
                  </el-col>
                </el-row>
                <el-row class="border_bottom items" v-for="(item, index) in selectSavingCard" :key="index">
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="7">
                        <div>
                          <span>{{ item.Name }}</span>
                          <span v-if="item.Alias">({{ item.Alias }})</span>
                        </div>
                      </el-col>
                      <el-col :span="6">
                        <el-input-number disabled :min="1" size="mini" style="width: 100px" v-model="item.number"></el-input-number>
                      </el-col>
                      <el-col :span="9" class="dis_flex flex_dir_row">
                        <!-- 本金 -->
                        <div style="width:40%">
                          <el-input v-model="item.effectivePrice" placeholder="可用余额" :min="0" class="marrt_10" type="number" v-enter-number2 size="mini" @input="arrearChange(item, 2)"></el-input>
                          <div class="font_12 color_gray">剩余本金：{{
                              item.Balance | NumFormat
                            }}</div>

                        </div>
                        <!-- 赠额 -->
                        <div style="width:40%" class="marlt_15">
                          <el-input v-model="item.effectiveGiftPrice" placeholder="有效赠额" :min="0" type="number" v-enter-number2 size="mini" @input="arrearChange(item, 3)"></el-input>
                          <div class="font_12 color_gray">剩余赠额：{{
                              item.LargessBalance | NumFormat
                            }}
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="2">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(2, index, item)"></el-button>
                      </el-col>
                    </el-col>
                  </el-col>
                  <el-col :span="24" class="pad_10 padbm_0">
                    <el-row class="cursor_pointer" v-for="(handler, pIndex) in item.handleTypeList" :key="pIndex" @click.native="
                        employeeHandleClick(2, selectSavingCard, item, index)
                      ">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName} [${employee.EmployeeID}] `">
                            <el-input class="employee_num" v-model="employee.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="
                                handlerPercentChange(handler.Employee, employee)
                              ">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5" style="
                                font-size: 16px;
                                color: rgb(144,147,153);
                                vertical-align: middle;
                              " v-on:click.stop="
                                removeHandleClick(handler, handleIndex)
                              "></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <!--时效卡-->
              <div v-if="selectTimeCard.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="8">时效卡</el-col>
                    <el-col :span="7">数量</el-col>
                    <el-col :span="7">退款金额</el-col>
                    <el-col :span="2">操作</el-col>
                  </el-col>
                </el-row>
                <el-row class="border_bottom items" v-for="(item, index) in selectTimeCard" :key="index">
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="8">
                        <div>
                          <span>{{ item.Name }}</span>
                          <span v-if="item.Alias">({{ item.Alias }})</span>
                          <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                        </div>
                      </el-col>
                      <el-col :span="7">
                        <el-input-number disabled :min="1" size="mini" style="width: 100px" v-model="item.number"></el-input-number>
                      </el-col>
                      <el-col :span="7">
                        <el-input v-model="item.effectivePrice" :disabled="item.IsLargess" :min="0" placeholder size="mini" type="number" v-enter-number2 style="width: 50%" @input="arrearChange(item, 4)"></el-input>
                      </el-col>
                      <el-col :span="2">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(3, index, item)"></el-button>
                      </el-col>
                    </el-col>
                    <el-col :span="24" style="margin-top: 4px">
                      <el-col :span="8">
                        <div class="color_red">
                          ¥ {{ item.Price | NumFormat }}
                        </div>
                      </el-col>
                      <el-col :span="6" :offset="7">
                        <span v-if="item.PricePreferentialAmount" class="font_12 color_gray">
                          手动改价：- {{item.PricePreferentialAmount | NumFormat}}</span>
                      </el-col>

                    </el-col>
                  </el-col>

                  <el-col :span="24" class="pad_10 padbm_0">
                    <el-row class="cursor_pointer" v-for="(handler, pIndex) in item.handleTypeList" :key="pIndex" @click.native="
                        employeeHandleClick(3, selectTimeCard, item, index)
                      ">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName} [${employee.EmployeeID}] `">
                            <el-input class="employee_num" v-model="employee.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="
                                handlerPercentChange(handler.Employee, employee)
                              ">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5" style="
                                font-size: 16px;
                                color: rgb(144,147,153);
                                vertical-align: middle;
                              " v-on:click.stop="
                                removeHandleClick(handler, handleIndex)
                              "></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <!--通用次卡-->
              <div v-if="selectGeneralCard.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="7">通用次卡</el-col>
                    <el-col :span="6">数量</el-col>
                    <el-col :span="6">金额</el-col>
                    <el-col :span="5">操作</el-col>
                  </el-col>
                </el-row>
                <el-row class="border_bottom items" v-for="(item, index) in selectGeneralCard" :key="index">
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="7">
                        <div>
                          <span>{{ item.Name }}</span>
                          <span v-if="item.Alias">({{ item.Alias }})</span>
                          <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                        </div>
                      </el-col>
                      <el-col :span="6">
                        <el-input-number :min="1" :disabled="item.allRefund" size="mini" style="width: 100px" v-model="item.number" :max="item.allRefund?item.Balance:item.ValidBalance" @change="numberChange(item, 2)"></el-input-number>
                      </el-col>
                      <el-col :span="6">
                        <el-input v-model="item.RefundPrice" :disabled="item.IsLargess" :min="0" placeholder size="mini" type="number" v-enter-number2 style="width: 50%" @input="arrearChange(item, 5)"></el-input>
                      </el-col>
                      <el-col :span="3">
                        <el-checkbox v-model="item.allRefund" @change="(evn)=>allRefundChangeClick(evn,item)">全退</el-checkbox>
                      </el-col>
                      <el-col :span="2">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(4, index, item)"></el-button>
                      </el-col>
                    </el-col>
                    <el-col :span="24" style="margin-top: 4px">
                      <el-col :span="8">
                        <div class="color_red">
                          ¥ {{ item.Price | NumFormat }}
                        </div>
                      </el-col>
                      <el-col :span="6" :offset="7">
                        <span v-if="item.PricePreferentialAmount" class="font_12 color_gray">
                          手动改价：- {{item.PricePreferentialAmount | NumFormat}}</span>
                      </el-col>
                    </el-col>
                  </el-col>

                  <el-col :span="24" class="pad_10 padbm_0">
                    <el-row class="cursor_pointer" v-for="(handler, pIndex) in item.handleTypeList" :key="pIndex" @click.native="
                        employeeHandleClick(4, selectGeneralCard, item, index)
                      ">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName} [${employee.EmployeeID}] `">
                            <el-input class="employee_num" v-model="employee.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="
                                handlerPercentChange(handler.Employee, employee)
                              ">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5" style="
                                font-size: 16px;
                                color: rgb(144,147,153);
                                vertical-align: middle;
                              " v-on:click.stop="
                                removeHandleClick(handler, handleIndex)
                              "></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>

              <!--套餐卡-->
              <div v-if="selectPackageCard.length > 0">
                <el-row class="border_bottom items" v-for="(item, index) in selectPackageCard" :key="index">

                  <el-row class="row_header border_bottom">
                    <el-col :span="24">
                      <el-col :span="8">套餐卡-{{item.Name}}</el-col>
                      <el-col :span="7">数量</el-col>
                      <el-col :span="7">退款金额</el-col>
                      <el-col :span="2">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" class="marlt_20" @click="removeSelectPackageItemClick(item,index)"></el-button>
                      </el-col>
                    </el-col>
                  </el-row>
                  <!-- 项目 -->
                  <div v-for="(ProjectItem,ProjectIndex) in item.Project" :key="ProjectIndex + 'sp' + 'pr'">
                    <el-col :span="24" class="pad_10">
                      <el-col :span="24">
                        <el-col :span="7">
                          <el-tag size="mini" class="marlt_5">项目</el-tag>
                          <el-tag v-if="ProjectItem.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                          {{ ProjectItem.Name }}
                        </el-col>
                        <el-col :span="6">
                          <el-input-number :disabled="ProjectItem.allRefund" v-model="ProjectItem.number" :min="1" :max="ProjectItem.allRefund?item.Balance:ProjectItem.ValidBalance" size="mini" style="width: 100px" @change="handleItemNumberChange(ProjectItem,'项目')"></el-input-number>
                        </el-col>
                        <el-col :span="6">
                          <span class="marrt_15 pad_10_0">
                            <el-input size="small" :disabled="ProjectItem.IsLargess" v-enter-number2 type="number" min="0" @input="packageInputPriceChange(ProjectItem, 5)" v-model="ProjectItem.effectivePrice" style="width: 45%"></el-input>
                          </span>
                        </el-col>
                        <el-col :span="3">
                          <el-checkbox v-model="ProjectItem.allRefund" @change="(evn)=>allPackageRefundChangeClick(evn,ProjectItem,1)">全退</el-checkbox>
                        </el-col>
                        <el-col :span="2">
                          <el-button type="danger" icon="el-icon-delete" circle size="mini" class="marlt_20" @click="
                                removeSelectItemClick(
                                  item,
                                  index,
                                  ProjectIndex,
                                  0
                                )
                              "></el-button>
                        </el-col>
                      </el-col>
                      <el-col :span="24">
                        <el-col :span="7">
                          <div class="color_red">
                            ¥ {{ ProjectItem.Amount }}
                          </div>
                        </el-col>
                        <el-col :span="7">
                          <span class="color_gray font_12">剩余次数:{{ ProjectItem.Balance }}次</span>
                        </el-col>
                        <el-col :span="6">
                          <span v-if="ProjectItem.PricePreferentialAmount" class="font_12 color_gray">
                            手动改价：- {{ProjectItem.PricePreferentialAmount.toFixed(2) | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </div>
                  <!-- 储值卡 -->
                  <div v-for="(savingCardItem,savingIndex) in item.SavingCard" :key="savingIndex + 'sp' + 's'">
                    <el-col :span="24" class="pad_10">
                      <el-col :span="7">
                        <el-tag size="mini" class="marlt_5">储值卡</el-tag>
                        {{ savingCardItem.Name }}
                      </el-col>
                      <el-col :span="6">
                        <el-input-number disabled v-model="savingCardItem.number" :min="1" size="mini" style="width: 100px"></el-input-number>
                      </el-col>
                      <el-col :span="9">
                        <el-input size="small" v-enter-number2 type="number" min="0" @input="packageInputPriceChange(savingCardItem, 1)" v-model="savingCardItem.effectivePrice" style="width: 45%"></el-input>
                        <el-input size="small" v-enter-number2 style="width: 45%" @input="packageInputPriceChange(savingCardItem, 2)" type="number" min="0" v-model="savingCardItem.effectiveGiftPrice" class="marlt_10"></el-input>
                      </el-col>
                      <el-col :span="2">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" class="marlt_20" @click="
                              removeSelectItemClick(
                                item,
                                index,
                                savingIndex,
                                1
                              )
                            "></el-button>
                      </el-col>
                      <el-col :span="24">
                        <el-col :span="7" style="color: transparent">
                          ¥{{ savingCardItem.Price }}
                        </el-col>
                        <el-col :offset="6" :span="9" class="color_gray font_12 dis_flex">
                          <div style="width: 50%">
                            剩余本金:
                            <span class="marlt_5">{{
                                  savingCardItem.Balance | NumFormat
                                }}元</span>
                          </div>
                          <div style="width: 50%">
                            剩余赠额:
                            <span class="marlt_5">{{
                                  savingCardItem.LargessBalance | NumFormat
                                }}元</span>
                          </div>
                        </el-col>
                      </el-col>
                    </el-col>
                  </div>
                  <!-- 通用次卡 -->
                  <div v-for="(generalCardItem,generalIndex) in item.GeneralCard" :key="generalIndex + 'sp' + 'g'">
                    <el-col :span="24" class="pad_10">
                      <el-col :span="24">
                        <el-col :span="7">
                          <el-tag size="mini" class="marlt_5">通用次卡</el-tag>
                          <el-tag v-if="generalCardItem.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                          {{ generalCardItem.Name }}
                        </el-col>
                        <el-col :span="6">
                          <el-input-number :disabled="generalCardItem.allRefund" v-model="generalCardItem.number" :min="1" :max="generalCardItem.allRefund?item.Balance:generalCardItem.ValidBalance" size="mini" style="width: 100px" @change="
                                handleItemNumberChange(
                                  generalCardItem,
                                  '通用次卡'
                                )
                              "></el-input-number>
                        </el-col>
                        <el-col :span="6">
                          <span class="marrt_15 pad_10_0">
                            <el-input size="small" v-enter-number2 type="number" min="0" v-model="generalCardItem.genAmount " @input="packageInputPriceChange(generalCardItem, 3)" style="width: 45%" :disabled="generalCardItem.IsLargess "></el-input>
                          </span>
                        </el-col>
                        <el-col :span="3">
                          <el-checkbox v-model="generalCardItem.allRefund" @change="(evn)=>allPackageRefundChangeClick(evn,generalCardItem,2)">全退</el-checkbox>
                        </el-col>
                        <el-col :span="2">
                          <el-button type="danger" icon="el-icon-delete" circle size="mini" class="marlt_20" @click="
                                removeSelectItemClick(
                                  item,
                                  index,
                                  generalIndex,
                                  2
                                )
                              "></el-button>
                        </el-col>
                      </el-col>
                      <el-col :span="24" class="color_red">
                        <el-col :span="7">
                          <div class="color_red">
                            ¥ {{ generalCardItem.Price }}
                          </div>
                        </el-col>
                        <el-col :span="6">
                          <span class="color_gray font_12">剩余次数:{{ generalCardItem.Balance }}次</span>
                        </el-col>
                        <el-col :span="6">
                          <span v-if="generalCardItem.PricePreferentialAmount" class="font_12 color_gray">
                            手动改价：- {{generalCardItem.PricePreferentialAmount.toFixed(2) | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </div>
                  <!-- 时效卡 -->
                  <div v-for="(timeCardItem,timeIndex) in item.TimeCard" :key="timeIndex + 'spt'">
                    <el-col :span="24" class="pad_10">
                      <el-col :span="24">
                        <el-col :span="7">
                          <el-tag size="mini" class="marlt_5">时效卡</el-tag>
                          <el-tag v-if="timeCardItem.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                          {{ timeCardItem.Name }}
                        </el-col>
                        <el-col :span="6">
                          <el-input-number v-model="timeCardItem.number" :min="1" :max="100" size="mini" style="width: 100px" disabled></el-input-number>
                        </el-col>
                        <el-col :span="9">
                          <span class="marrt_15 pad_10_0">
                            <el-input size="small" v-enter-number2 type="number" min="0" @input="packageInputPriceChange(timeCardItem, 4)" v-model="timeCardItem.effectivePrice" style="width: 45%" :disabled="timeCardItem.IsLargess"></el-input>
                          </span>
                        </el-col>
                        <el-col :span="2">
                          <el-button type="danger" icon="el-icon-delete" circle size="mini" class="marlt_20" @click="
                                removeSelectItemClick(
                                  item,
                                  index,
                                  timeIndex,
                                  3
                                )
                              "></el-button>
                        </el-col>
                      </el-col>
                      <el-col :span="24">
                        <el-col :span="6">
                          <div class="color_red">
                            ¥ {{ timeCardItem.Price | NumFormat }}
                          </div>
                        </el-col>

                        <el-col :span="9" :offset="6">
                          <span v-if="timeCardItem.PricePreferentialAmount" class="font_12 color_gray">
                            手动改价：- {{timeCardItem.PricePreferentialAmount.toFixed(2) | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </div>
                  <!-- 产品 -->
                  <div v-for="(ProductItem,ProductIndex) in item.Product" :key="ProductIndex + 'sp' +'d'">
                    <el-col :span="24" class="pad_10">
                      <el-col :span="24">
                        <el-col :span="7">
                          <el-tag size="mini" class="marlt_5">产品</el-tag>
                          <el-tag v-if="ProductItem.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                          {{ ProductItem.Name }}
                        </el-col>
                        <el-col :span="6">
                          <el-input-number :disabled="ProductItem.allRefund" v-model="ProductItem.number" :min="1" :max="ProductItem.allRefund?item.Balance:ProductItem.ValidBalance" size="mini" style="width: 100px" @change="
                                handleItemNumberChange(
                                  ProductItem,
                                  '产品'
                                )
                              "></el-input-number>
                        </el-col>
                        <el-col :span="6">
                          <span class="marrt_15 pad_10_0">
                            <el-input size="small" :disabled="ProductItem.IsLargess" v-enter-number2 type="number" min="0" @input="packageInputPriceChange(ProductItem, 5)" v-model="ProductItem.effectivePrice" style="width: 45%"></el-input>
                          </span>
                        </el-col>
                        <el-col :span="3">
                          <el-checkbox v-model="ProductItem.allRefund" @change="(evn)=>allPackageRefundChangeClick(evn,ProductItem,1)">全退</el-checkbox>
                        </el-col>
                        <el-col :span="2">
                          <el-button type="danger" icon="el-icon-delete" circle size="mini" class="marlt_20" @click="
                                removeSelectItemClick(
                                  item,
                                  index,
                                  ProductIndex,
                                  4
                                )
                              "></el-button>
                        </el-col>
                      </el-col>
                      <el-col :span="24">
                        <el-col :span="7">
                          <div class="color_red">
                            ¥ {{ ProductItem.Amount }}
                          </div>
                        </el-col>
                        <el-col :span="6">
                          <span class="color_gray font_12">剩余次数:{{ ProductItem.Balance }}次</span>
                        </el-col>
                        <el-col :span="6">
                          <span v-if="ProductItem.PricePreferentialAmount" class="font_12 color_gray">
                            手动改价：- {{ProductItem.PricePreferentialAmount.toFixed(2) | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </div>

                  <el-col :span="24" class="pad_10 padbm_0 border_top">
                    <el-row class="cursor_pointer" v-for="(handler, pIndex) in item.handleTypeList" :key="pIndex" @click.native="
                        employeeHandleClick(5, selectPackageCard, item, index)
                      ">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName} [${employee.EmployeeID}] `">
                            <el-input class="employee_num" v-model="employee.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="
                                handlerPercentChange(
                                  handler.Employee,
                                  employee,
                                  '套餐卡'
                                )
                              ">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5" style="
                                font-size: 16px;
                                color: rgb(144,147,153);
                                vertical-align: middle;
                              " v-on:click.stop="
                                removeHandleClick(
                                  handler,
                                  handleIndex,
                                  '套餐卡'
                                )
                              "></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
            </el-scrollbar>
          </el-main>
          <el-footer class="border_top">
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <span class="font_14 color_maroon">
                  退款金额：¥{{ parseFloat(totalAmount).toFixed(2) | NumFormat }}</span>
              </el-col>
              <el-col :span="14">
                <el-input v-model="Remark" type="textarea" :rows="1" placeholder="请输入备注信息"></el-input>
              </el-col>
              <el-col :span="2" class="text_right">
                <el-button type="plain" size="small" @click="billClickClear">清空</el-button>
              </el-col>
              <el-col :span="2" class="text_right">
                <el-button :loading="createRefundLoading" type="primary" size="small" @click="billClick">结账</el-button>
              </el-col>
            </el-row>
          </el-footer>
        </el-container>
      </el-col>
    </el-row>
    <!-- 套餐卡明细 -->
    <el-dialog :visible.sync="packageDetailVisible" custom-class="packageDetailDialogClass" width="800px">
      <div slot="title" class="font_14 color_333">{{currentSelectPackageItem.Name}}-套餐卡明细</div>
      <el-scrollbar style="height:60vh">
        <el-row v-if="packageCardDetailList">
          <!-- 套餐卡项目 -->
          <div v-if="packageCardDetailList.Project.length > 0">
            <el-card v-for="(item,index) in packageCardDetailList.Project" :key="index + 'p' + 'pr'" class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index == 0 ? 'martp_10' : ''" :body-style="item.RefundBalance>0?{padding: '0px' ,'background-color': '#F5F7FA' }:{ padding: '0px'}" :shadow="item.RefundBalance>0?'never':'hover'" @click.native="selectPackageDetailItem(item, 0)">
              <div slot="header">
                <el-tag size="mini">项目</el-tag>
                <span>{{ item.Name }}</span>
                <span v-if="item.Alias">({{ item.Alias }})</span>
                <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                <el-tag v-if="item.RefundBalance > 0" size="mini" class="marlt_5" type="danger">退款处理中</el-tag>
              </div>
              <el-row class="border_bottom">
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">有效次数：</span>
                    <span>{{ item.ValidBalance }}</span>
                    <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                      <p>
                        有效次数=
                        剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                      </p>
                      <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                    </el-popover>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="goods-item">
                    <span class="goods-lable">剩余数量：</span>
                    <span>{{ item.Balance }}</span>
                  </div>
                </el-col>
                <el-col :span="8" class="border_left">
                  <div class="goods-item">
                    <span class="goods-lable">退款中数量：</span>
                    <span>{{ item.RefundBalance }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row class="border_bottom">
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">购买价格：</span>
                    <span v-if="item.IsLargess">¥ 0.00</span>
                    <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="goods-item">
                    <span class="goods-lable">购买数量：</span>
                    <span>{{ item.Quantity }}</span>
                  </div>
                </el-col>
                <el-col :span="8" class="border_left">
                  <div class="goods-item">
                    <span class="goods-lable">参考单价：</span>
                    <span>¥ {{ item.Amount | NumFormat }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">欠款金额：</span>
                    <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="goods-item">
                    <span class="goods-lable">购买日期：</span>
                    <span>{{ item.BuyDate }}</span>
                  </div>
                </el-col>
                <el-col :span="8" class="border_left">
                  <div class="goods-item">
                    <span class="goods-lable">有效期：</span>
                    <span>{{ item.ValidDayName }}</span>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </div>
          <!-- 套餐卡储值卡 -->
          <div v-if="packageCardDetailList.SavingCard.length > 0">
            <el-card v-for="(item,index) in packageCardDetailList.SavingCard" :key="index +'p' + 's'" class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index == 0 ? 'martp_10' : ''" :body-style="(item.RefundAmount>0 && item.ValidBalance == 0)?{padding: '0px' ,'background-color': '#F5F7FA' }:{ padding: '0px'}" :shadow="(item.RefundAmount>0 && item.ValidBalance == 0)?'never':'hover'" @click.native="selectPackageDetailItem(item, 1)">
              <div slot="header">
                <el-tag size="mini">储值卡</el-tag>
                <span>{{ item.Name }}</span>
                <span v-if="item.Alias">({{ item.Alias }})</span>
                <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                <el-tag v-if="item.RefundAmount>0 && item.ValidBalance == 0" size="mini" class="marlt_5" type="danger">退款处理中</el-tag>
              </div>
              <el-row class="border_bottom">
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">可用余额：</span>
                    <span>¥ {{ item.ValidBalance | NumFormat }}</span>
                    <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                      <p>
                        1.有效金额= 剩余数量-退款中金额-欠款金额；
                      </p>
                      <p>
                        2.注意：如果有赠额的情况，赠额可用金额也跟退款金额和欠款金额有关；
                      </p>
                      <p>
                        3.比如：充值100送100，欠款50，则可用本金为50，可用赠额为50，可用余额为100。
                      </p>
                      <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                    </el-popover>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="goods-item">
                    <span class="goods-lable">剩余金额：</span>
                    <span>¥ {{ item.TotalBalance | NumFormat }}</span>
                    <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                      <p>剩余金额= 剩余本金+剩余赠额</p>
                      <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                    </el-popover>
                  </div>
                </el-col>
                <el-col :span="8" class="border_left">
                  <div class="goods-item">
                    <span class="goods-lable">退款中金额：</span>
                    <span>¥ {{ item.RefundAmount | NumFormat }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row class="border_bottom">
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">购买金额：</span>
                    <span>¥ {{ item.TotalAmount | NumFormat }}</span>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="goods-item">
                    <span class="goods-lable">剩余本金：</span>
                    <span>¥ {{ item.Balance | NumFormat }}</span>
                  </div>
                </el-col>
                <el-col :span="8" class="border_left">
                  <div class="goods-item">
                    <span class="goods-lable">剩余赠额：</span>
                    <span>¥ {{ item.LargessBalance | NumFormat }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">欠款金额：</span>
                    <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="goods-item">
                    <span class="goods-lable">购买日期：</span>
                    <span>{{ item.BuyDate }}</span>
                  </div>
                </el-col>
                <el-col :span="8" class="border_left">
                  <div class="goods-item">
                    <span class="goods-lable">有效期：</span>
                    <span>{{ item.ValidDayName }}</span>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </div>
          <!-- 套餐卡时效卡 -->
          <div v-if="packageCardDetailList.TimeCard.length > 0">
            <el-card v-for="(item,index) in packageCardDetailList.TimeCard" :key="index + 'p' + 't'" class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index == 0 ? 'martp_10' : ''" :body-style="item.IsRefund?{padding: '0px' ,'background-color': '#F5F7FA' }:{ padding: '0px'}" :shadow="item.IsRefund?'never':'hover'" @click.native="selectPackageDetailItem(item, 2)">

              <div slot="header">
                <el-tag size="mini">时效卡</el-tag>
                <span>{{ item.Name }}</span>
                <span v-if="item.Alias">({{ item.Alias }})</span>
                <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                <el-tag v-if="item.IsRefund > 0" size="mini" class="marlt_5" type="danger">退款处理中</el-tag>
              </div>
              <el-row class="border_bottom">
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">周期内消耗次数：</span>
                    <span>{{ item.ConsumeCycleAmount }}</span>
                    <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                      <p>
                        当“周期内消耗次数”超过“消耗周期限制”，则不能使用。
                      </p>
                      <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                    </el-popover>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="goods-item">
                    <span class="goods-lable">消耗周期限制：</span>
                    <span>{{
                        item.ConsumeCycle == 0 ||
                        item.CycleLimitAmount == 0
                          ? "不限制"
                          : item.CycleLimitAmount +
                            "(次)/" +
                            item.ConsumeCycle +
                            "(天)"
                      }}</span>
                  </div>
                </el-col>
                <el-col :span="8" class="border_left">
                  <div class="goods-item">
                    <span class="goods-lable">累计耗用次数：</span>
                    <span>{{ item.ConsumeAmount }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row class="border_bottom">
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">购买金额：</span>
                    <span v-if="item.IsLargess">¥ 0.00</span>
                    <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="goods-item">
                    <span class="goods-lable">业绩提成次数：</span>
                    <span>{{ item.PerformanceBalance }}</span>
                    <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                      <p>用于确认员工消耗业绩。</p>
                      <p>
                        比如：购买金额为1000，业绩提成次数为10，则每次使用时效卡耗做项目，则1~10次时，员工业绩为100，11次以后（包含第11次），员工业绩为0。
                      </p>
                      <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                    </el-popover>
                  </div>
                </el-col>
                <el-col :span="8" class="border_left">
                  <div class="goods-item">
                    <span class="goods-lable">参考单价：</span>
                    <span>¥ {{ item.Amount | NumFormat }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">欠款金额：</span>
                    <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="goods-item">
                    <span class="goods-lable">购买日期：</span>
                    <span>{{ item.BuyDate }}</span>
                  </div>
                </el-col>
                <el-col :span="8" class="border_left">
                  <div class="goods-item">
                    <span class="goods-lable">有效期：</span>
                    <span>{{ item.ValidDayName }}</span>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </div>
          <!-- 套餐卡通用次卡 RefundAmount -->
          <div v-if="packageCardDetailList.GeneralCard.length > 0">

            <el-card v-for="(item,index) in packageCardDetailList.GeneralCard" :key="index + 'p' + 'g'" class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index == 0 ? 'martp_10' : ''" :body-style="item.RefundAmount>0?{padding: '0px' ,'background-color': '#F5F7FA' }:{ padding: '0px'}" :shadow="item.RefundAmount>0?'never':'hover'" @click.native="selectPackageDetailItem(item, 3)">
              <div slot="header">
                <el-tag size="mini">通用次卡</el-tag>
                <span>{{ item.Name }}</span>
                <span v-if="item.Alias">({{ item.Alias }})</span>
                <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                <el-tag v-if="item.RefundAmount > 0" size="mini" class="marlt_5" type="danger">退款处理中</el-tag>
              </div>
              <el-row class="border_bottom">
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">有效次数：</span>
                    <span>{{ item.ValidBalance }}</span>
                    <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                      <p>
                        有效次数=
                        剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                      </p>
                      <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                    </el-popover>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="goods-item">
                    <span class="goods-lable">剩余次数：</span>
                    <span>{{ item.Balance }}</span>
                  </div>
                </el-col>
                <el-col :span="8" class="border_left">
                  <div class="goods-item">
                    <span class="goods-lable">退款中数量：</span>
                    <span>{{ item.RefundAmount }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row class="border_bottom">
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">购买金额：</span>
                    <span v-if="item.IsLargess">¥ 0.00</span>
                    <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="goods-item">
                    <span class="goods-lable">卡总次数：</span>
                    <span>{{ item.CardTimes }}</span>
                  </div>
                </el-col>
                <el-col :span="8" class="border_left">
                  <div class="goods-item">
                    <span class="goods-lable">参考单价：</span>
                    <span>¥ {{ item.Amount | NumFormat }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">欠款金额：</span>
                    <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="goods-item">
                    <span class="goods-lable">购买日期：</span>
                    <span>{{ item.BuyDate }}</span>
                  </div>
                </el-col>
                <el-col :span="8" class="border_left">
                  <div class="goods-item">
                    <span class="goods-lable">有效期：</span>
                    <span>{{ item.ValidDayName }}</span>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </div>
          <!-- 套餐卡产品 -->
          <div v-if="packageCardDetailList.Product.length > 0">
            <el-card v-for="(item,index) in packageCardDetailList.Product" :key="index + 'p' + 'd'" class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index == 0 ? 'martp_10' : ''" :body-style="item.RefundBalance>0?{padding: '0px' ,'background-color': '#F5F7FA' }:{ padding: '0px'}" :shadow="item.RefundBalance>0?'never':'hover'" @click.native="selectPackageDetailItem(item, 4)">
              <div slot="header">
                <el-tag size="mini">产品</el-tag>
                <span>{{ item.Name }}</span>
                <span v-if="item.Alias">({{ item.Alias }})</span>
                <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                <el-tag v-if="item.RefundBalance > 0" size="mini" class="marlt_5" type="danger">退款处理中</el-tag>
              </div>
              <el-row class="border_bottom">
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">有效数量：</span>
                    <span>{{ item.ValidBalance }}</span>
                    <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                      <p>
                        有效数量=
                        剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                      </p>
                      <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                    </el-popover>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="goods-item">
                    <span class="goods-lable">剩余数量：</span>
                    <span>{{ item.Balance }}</span>
                  </div>
                </el-col>
                <el-col :span="8" class="border_left">
                  <div class="goods-item">
                    <span class="goods-lable">退款中数量：</span>
                    <span>{{ item.RefundBalance }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row class="border_bottom">
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">购买价格：</span>
                    <span v-if="item.IsLargess">¥ 0.00</span>
                    <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="goods-item">
                    <span class="goods-lable">购买数量：</span>
                    <span>{{ item.Quantity }}</span>
                  </div>
                </el-col>
                <el-col :span="8" class="border_left">
                  <div class="goods-item">
                    <span class="goods-lable">参考单价：</span>
                    <span>¥ {{ item.Amount | NumFormat }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">欠款金额：</span>
                    <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="goods-item">
                    <span class="goods-lable">购买日期：</span>
                    <span>{{ item.BuyDate }}</span>
                  </div>
                </el-col>
                <el-col :span="8" class="border_left">
                  <div class="goods-item">
                    <span class="goods-lable">有效期：</span>
                    <span>{{ item.ValidDayName }}</span>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </div>
        </el-row>
      </el-scrollbar>
    </el-dialog>

    <!--经手人-->
    <el-dialog title="经手人" :visible.sync="dialogVisible" width="800px">
      <div>
        <el-row class="padbm_10">
          <el-col :span="8">
            <el-input placeholder="请输入员工编号、姓名" prefix-icon="el-icon-search" v-model="handlerName" size="small" clearable></el-input>
          </el-col>
        </el-row>
        <el-tabs v-model="tabHandle">
          <el-tab-pane :label="handler.Name" :name="`${index}`" v-for="(handler, index) in handlerList" :key="index">
            <el-row style="max-height: 300px; overflow-y: auto">
              <el-col :span="12" v-for="item in handler.Employee.filter(
                  (item) =>
                    !handlerName ||
                    item.EmployeeName.toLowerCase().includes(
                      handlerName.toLowerCase()
                    ) ||
                    item.EmployeeID.toLowerCase().includes(
                      handlerName.toLowerCase()
                    )
                )" :key="item.EmployeeID" class="marbm_10 dis_flex flex_y_center">
                <el-checkbox v-model="item.Checked" @change="handlerCheckedChange(handler.Employee, item)">
                  <span class="marrt_10">{{ item.EmployeeName }} [{{ item.EmployeeID }}]</span>
                </el-checkbox>
                <el-input placeholder v-model="item.Discount" style="width: 120px" type="number" size="mini" min="0" max="100" v-enter-number @input="handlerPercentChange(handler.Employee, item)">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submitHandleClick">确 定</el-button>
      </div>
    </el-dialog>

    <!--结账  
                :close-on-click-modal="false"
                :close-on-press-escape="false"
    :show-close="false"-->
    <el-dialog title="结账" :visible.sync="dialogBill" width="900px" class="dialogs">
      <el-row>
        <el-col :span="8">
          <el-scrollbar class="el-scrollbar_height" style="height: 500px">
            <div class="marrt_10">
              <div class="dis_flex">
                <span class="flex_box text_center font_16" style="line-height: 32px">{{ entityName }}</span>
              </div>
              <el-divider>
                <span class="font_12 color_gray">订单信息</span>
              </el-divider>

              <div class="dis_flex">
                <span class="flex_box font_12 color_gray text-left line_height_24">会员姓名</span>
                <span class="flex_box font_12 text_right line_height_24">{{
                  customerFullName
                }}</span>
              </div>
              <div class="dis_flex">
                <span class="flex_box font_12 color_gray text-left line_height_24">会员手机号</span>
                <span class="flex_box font_12 text_right line_height_24">{{
                  customerPhoneNumber
                }}</span>
              </div>
              <div class="dis_flex">
                <span class="flex_box font_12 color_gray text-left line_height_24">收银员</span>
                <span class="flex_box font_12 text_right line_height_24">{{
                  userName
                }}</span>
              </div>
              <el-divider>
                <span class="font_12 color_gray">消费明细</span>
              </el-divider>

              <template v-for="(item, index) in selectProject">
                <div :key="index + 'j_p'">
                  <div class="dis_flex">
                    <span class="color_gray text-left line_height_24" style="flex: 2">{{ item.Name }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款数量</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.number }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款金额</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.RefundPrice | NumFormat }}</span>
                  </div>
                </div>
              </template>

              <template v-for="(item, index) in selectProduct">
                <div :key="index + 'j_pd'">
                  <div class="dis_flex">
                    <span class="color_gray text-left line_height_24" style="flex: 2">{{ item.Name }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款数量</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.number }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款金额</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.RefundPrice | NumFormat }}</span>
                  </div>
                </div>
              </template>

              <template v-for="(item, index) in selectSavingCard">
                <div :key="index + 'j_s'">
                  <div class="dis_flex">
                    <span class="color_gray text-left line_height_24" style="flex: 2">{{ item.Name }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款数量</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.number }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退有效本金</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.effectivePrice | NumFormat }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退有效赠送</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.effectiveGiftPrice | NumFormat }}</span>
                  </div>
                </div>
              </template>

              <template v-for="(item, index) in selectPackageCard">
                <span :key="index" class="color_gray text-left line_height_24" style="flex: 2">{{ item.Name }}</span>
                <!-- <div v-for="(childItem, index) in item.ChildItems" :key="index"> -->
                <!-- 项目 -->
                <div v-for="(ProjectItem,ProjectIndex ) in item.Project" :key="ProjectIndex + 'j_p' + 'pr' + index">
                  <div class="dis_flex">
                    <span class="color_gray text-left line_height_24" style="flex: 2">{{ ProjectItem.Name }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款数量</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">{{ ProjectItem.number }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款金额</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ ProjectItem.effectivePrice | NumFormat }}</span>
                  </div>
                </div>
                <!-- 产品 -->
                <div v-for="(ProductItem,ProductIndex ) in item.Product" :key="ProductIndex + 'j_p' + 'pd'  + index">
                  <div class="dis_flex">
                    <span class="color_gray text-left line_height_24" style="flex: 2">{{ ProductItem.Name }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款数量</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">{{ ProductItem.number }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款金额</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ ProductItem.effectivePrice | NumFormat }}</span>
                  </div>
                </div>
                <!-- 储值卡 -->
                <div v-for="(SavingCardItem,SavingCardIndex ) in item.SavingCard" :key="SavingCardIndex + 'j_p' + 's'  + index">
                  <div class="dis_flex">
                    <span class="color_gray text-left line_height_24" style="flex: 2">{{ SavingCardItem.Name }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款数量</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">{{ SavingCardItem.number }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退有效本金</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ SavingCardItem.effectivePrice | NumFormat }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退有效赠额</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ SavingCardItem.effectiveGiftPrice | NumFormat }}</span>
                  </div>
                </div>
                <!-- 通用次卡 -->
                <div v-for="(GeneralCardItem,GeneralCardIndex ) in item.GeneralCard" :key="GeneralCardIndex + 'jp' + 'g'  + index">
                  <div class="dis_flex">
                    <span class="color_gray text-left line_height_24" style="flex: 2">{{ GeneralCardItem.Name }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款数量</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">{{ GeneralCardItem.number }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款金额</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ GeneralCardItem.effectivePrice | NumFormat }}</span>
                  </div>
                </div>
                <!-- 时效卡 -->
                <div v-for="(TimeCardItem,TimeCardIndex ) in item.TimeCard" :key="TimeCardIndex + 'j_p' + 't'  + index">
                  <div class="dis_flex">
                    <span class="color_gray text-left line_height_24" style="flex: 2">{{ TimeCardItem.Name }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款数量</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">{{ TimeCardItem.number }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款金额</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ TimeCardItem.effectivePrice | NumFormat }}</span>
                  </div>
                </div>
                <!-- </div> -->
              </template>

              <template v-for="(item, index) in selectTimeCard">
                <div :key="index + 'j_t'">
                  <div class="dis_flex">
                    <span class="color_gray text-left line_height_24" style="flex: 2">{{ item.Name }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款数量</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.number }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款金额</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.effectivePrice | NumFormat }}</span>
                  </div>
                </div>
              </template>

              <template v-for="(item, index) in selectGeneralCard">
                <div :key="index + 'j_g'">
                  <div class="dis_flex">
                    <span class="color_gray text-left line_height_24" style="flex: 2">{{ item.Name }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款数量</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.number }}</span>
                  </div>
                  <div class="dis_flex">
                    <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">退款金额</span>
                    <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.RefundPrice | NumFormat }}</span>
                  </div>
                </div>
              </template>
              <el-divider class="sell-el-divider"></el-divider>
              <div class="dis_flex">
                <span class="flex_box color_gray text-left line_height_24">合计</span>
                <span class="flex_box text_right line_height_24">￥{{ payInfo.TotalAmount | NumFormat }}</span>
              </div>
            </div>
          </el-scrollbar>
        </el-col>
        <el-col :span="16">
          <el-row type="flex" align="middle">
            <el-col :span="24" class="marlt_10">
              <div class="marbm_10">
                <span class="font_20">订单金额：</span>
                <span class="font_20">¥{{ payInfo.TotalAmount | NumFormat }}</span>
              </div>
              <div class="font_12 color_999 marbm_5">提示:储值卡抵扣金额退回对应的储值卡内</div>
              <div>
                <span>
                  现金：
                  <span class="color_red">¥{{ payInfo.PayAmount | NumFormat }}</span>
                </span>
                <span class="marlt_10">
                  赠送卡扣:
                  <span class="color_red">¥{{ payInfo.CardDeductionLargessAmount | NumFormat }}</span>
                </span>
                <span class="marlt_10">
                  非赠送卡扣:
                  <span class="color_red">¥{{ payInfo.CardDeductionAmount | NumFormat }}</span>
                </span>
              </div>
            </el-col>
          </el-row>
          <el-scrollbar class="el-scrollbar_height" style="height: 415px">
            <div v-if="Object.keys(payInfo).length > 0">
              <div v-if="payInfo.SavingCardDeduction.length > 0">
                <div style="margin-top: 20px; margin-bottom: 0px" class="marlt_10">
                  <el-col :span="10"> 退回储值卡 </el-col>
                  <el-col :span="7" class="marlt_10"> 赠送 </el-col>
                  <el-col :span="6" class="marlt_10"> 非赠送 </el-col>
                </div>
                <el-table :data="payInfo.SavingCardDeduction" size="small" class="padtp_15" :show-header="false">
                  <el-table-column prop="Name" label="选择退款方式"></el-table-column>
                  <el-table-column>
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.LargessAmount" size="small" type="number" min="0" v-enter-number2 placeholder="储值卡抵扣赠送金额退款" disabled @input="
                          paySavingPriceChange(scope.row, 1, scope.$index)
                        "></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column>
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.Amount" size="small" type="number" min="0" v-enter-number2 placeholder="储值卡抵扣本金退款" disabled @input="
                          paySavingPriceChange(scope.row, 2, scope.$index)
                        "></el-input>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!-- v-if="payInfo.PayMethod.length>0" -->
              <div>
                <div style="margin-top: 20px; margin-bottom: 0px" class="marlt_10">
                  退回现金
                </div>
                <el-table :data="payList" size="small" class="padtp_15" :show-header="false">
                  <el-table-column prop="payName" label="选择退款方式">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.PayMethodID" placeholder="选择退款方式" size="small" clearable filterable @change="payMethodChange(scope.row)">
                        <el-option v-for="item in payTypeList" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column prop="price" label="退款金额">
                    <template slot-scope="scope">
                      <el-input clearable v-model="scope.row.Amount" size="small" type="number" min="0" v-enter-number2 placeholder="请输入退款金额" :disabled="scope.row.PayMethodID == ''" @input="payPriceChange(scope.row)"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="操作" width="100">
                    <template slot-scope="scope">
                      <el-button type="danger" icon="el-icon-close" circle size="mini" @click="removePayClick(scope.$index)" v-if="scope.$index + 1 != 1"></el-button>
                      <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="addPayclick"></el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-scrollbar>
        </el-col>
      </el-row>

      <div slot="footer">
        <el-button @click="dialogBill = false" size="small" :disabled="modalLoading">取 消</el-button>
        <el-button type="primary" @click="submitPayClick" :loading="modalLoading" v-prevent-click size="small">提交申请</el-button>
      </div>
    </el-dialog>

    <!--结账成功-->
    <el-dialog :visible.sync="dialogSuccess" width="450px">
      <div class="text_center pad_15">
        <i class="el-icon-document" style="font-size: 80px ;color: #999"></i>
        <div class="pad_15 color_primary font_weight_600 font_18">订单已结账成功</div>
      </div>
      <el-row class="pad_15 border_bottom">
        <el-col :span="12">退款订单号：</el-col>
        <el-col :span="12" class="text_right">{{billOrderNumber}}</el-col>
      </el-row>
      <el-row class="pad_15 border_bottom">
        <el-col :span="12">订单金额：</el-col>
        <el-col :span="12" class="color_red text_right">¥{{refundTotalAmount_s | NumFormat}}</el-col>
      </el-row>
      <el-row class="pad_15 border_bottom">
        <el-col :span="5">订单备注：</el-col>
        <el-col :span="19">{{sucRemark}}</el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogSuccess = false" size="small">继续开单</el-button>
        <el-button type="primary" @click="printOrderReceipt" :loading="printLoading" size="small">打印小票</el-button>
      </div>
    </el-dialog>

    <!-- 打印小票 销售 退 订单 -->
    <el-dialog :visible.sync="refundCashierReceiptDialogVisible" width="300px">
      <span slot="title" class="font_14 color_333">打印小票</span>
      <div v-if="saleOrderDetail && userInfo">
        <el-row>
          <el-col :span="24">
            <el-scrollbar class="el-scrollbar_height" style="height:500px">
              <div class="marrt_10">
                <div class="dis_flex">
                  <span class="flex_box text_center font_16" style="line-height:32px">{{userInfo.EntityName}}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">退款信息</span>
                </el-divider>

                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">订单编号</span>
                  <span class="font_12 text_right line_height_24" style="flex:3;">{{saleOrderDetail.ID}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">下单时间</span>
                  <span class="font_12 text_right line_height_24" style="flex:3;">{{saleOrderDetail.BillDate}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员姓名</span>
                  <span class="flex_box font_12 text_right line_height_24">{{cashierReceipt.NameEncrypt?formatName(saleOrderDetail.Name) : saleOrderDetail.Name}}</span>

                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员手机号</span>
                  <span class="flex_box font_12 text_right line_height_24">{{cashierReceipt.MobileEncrypt?formatPhone(saleOrderDetail.PhoneNumber):saleOrderDetail.PhoneNumber}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">收银员</span>
                  <span class="flex_box font_12 text_right line_height_24">{{saleOrderDetail.EmployeeName}}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">退款明细</span>
                </el-divider>
                <template v-for="(item,index) in saleOrderDetail.Project">
                  <div :key="index + 'Project' + item.ProjectID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1}} {{item.ProjectName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>
                    <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyAmount && !item.IsLargess" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.AccountTotalAmount | NumFormat}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundableAmount  && !item.IsLargess" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">可退金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{item.TotalAmount + item.PricePreferentialAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{item.IsLargess?'0.00':item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.SavingCard">
                  <div :key="index + 'SavingCard' + item.SavingCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">{{index+1+saleOrderDetail.Project.length}} {{item.SavingCardName}}</span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{(item.Amount/item.Quantity).toFixed(2) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">充值金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{(item.AccountTotalAmount).toFixed(2) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.AccountLargessAmount > 0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{(item.AccountLargessAmount).toFixed(2) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Amount | NumFormat}}</span>
                    </div>

                    <div class="dis_flex" v-if="item.LargessAmount > 0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款赠额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.LargessAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.TimeCard">
                  <div :key="index+'TimeCard'+item.TimeCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length}} {{item.TimeCardName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyAmount  && !item.IsLargess" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.AccountTotalAmount | NumFormat}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundableAmount  && !item.IsLargess" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">可退金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{item.TotalAmount + item.PricePreferentialAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{item.IsLargess?'0.00':item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.GeneralCard">
                  <div :key="index+'GeneralCard'+item.GeneralCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length+saleOrderDetail.TimeCard.length}} {{item.GeneralCardName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购卡次数</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.AccountCardTimes}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyAmount  && !item.IsLargess" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.AccountTotalAmount | NumFormat}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退卡次数</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.CardTimes}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundableAmount  && !item.IsLargess" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">可退金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{item.TotalAmount + item.PricePreferentialAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{item.IsLargess?'0.00':item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.Product">
                  <div :key="index+'Product'+item.ProductID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length+saleOrderDetail.TimeCard.length+saleOrderDetail.GeneralCard.length}} {{item.ProductName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyQuantity  " class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.AccountQuantity}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyAmount  && !item.IsLargess" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.AccountTotalAmount | NumFormat}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyQuantity  " class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundableAmount  && !item.IsLargess" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">可退金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{item.TotalAmount + item.PricePreferentialAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{item.IsLargess?'0.00':item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.PackageCard">
                  <div :key="index+'PackageCard'+item.PackageCardID">
                    <!-- 套餐卡项目 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardProject" :key="Pindex + 'PProjectID' + packItem.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length+saleOrderDetail.TimeCard.length+saleOrderDetail.GeneralCard.length + saleOrderDetail.Product.length}} {{packItem.Name}}
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.AccountQuantity}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.AccountTotalAmount | NumFormat}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundableAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">可退金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{packItem.TotalAmount + item.PricePreferentialAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{packItem.IsLargess?'0.00':packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡储值卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardSavingCard" :key="Pindex + 'PSavingCardID' + packItem.SavingCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardGoods.PackageCardProject.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length 
                          +saleOrderDetail.Product.length}} {{packItem.Name}}
                          <span class="font_12" size="mini" v-if="packItem.IsLargess">(赠)</span>
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">充值金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥{{(packItem.AccountTotalAmount || 0).toFixed(2) | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="packItem.AccountLargessAmount > 0">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥{{(packItem.AccountLargessAmount || 0).toFixed(2) | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.Amount | NumFormat}}</span>
                      </div>

                      <div class="dis_flex" v-if="item.LargessAmount > 0">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款赠额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.LargessAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡时效卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardTimeCard" :key="Pindex + 'PTimeCardID' + packItem.TimeCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length 
                          +saleOrderDetail.Product.length}} {{packItem.Name}}
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.PackageQuantity}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.AccountTotalAmount | NumFormat}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundableAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">可退金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{packItem.TotalAmount + item.PricePreferentialAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{packItem.IsLargess?'0.00':packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡通用次卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardGeneralCard" :key="Pindex + 'PGeneralCardID' + packItem.GeneralCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length 
                          +saleOrderDetail.Product.length}} {{packItem.Name}}
                          <span class="font_12" size="mini" v-if="packItem.IsLargess">(赠)</span>
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购卡次数</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.AccountCardTimes}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.AccountTotalAmount | NumFormat}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退卡次数</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.CardTimes}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundableAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">可退金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{packItem.TotalAmount + item.PricePreferentialAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{packItem.IsLargess?'0.00':packItem.TotalAmount | NumFormat}}</span>
                      </div>

                    </div>

                    <!-- 套餐卡产品 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardProduct" :key="Pindex + 'PProduct' + packItem.ProductID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +item.PackageCardGoods.PackageCardGeneralCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length 
                          + saleOrderDetail.Product.length}} {{packItem.Name}}
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.AccountQuantity}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.AccountTotalAmount | NumFormat}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundableAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">可退金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{packItem.TotalAmount + item.PricePreferentialAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{packItem.IsLargess?'0.00':packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 赠送 -->
                    <!-- 套餐卡项目 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardLargessGoods.PackageCardProject" :key="Pindex + 'PProjectID' + packItem.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length 
                          +saleOrderDetail.Product.length
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +item.PackageCardGoods.PackageCardGeneralCard.length
                          +item.PackageCardGoods.PackageCardProduct.length
                          }} {{packItem.Name}}【赠】
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.AccountQuantity}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{packItem.IsLargess?'0.00':packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡储值卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardLargessGoods.PackageCardSavingCard" :key="Pindex + 'PSavingCardID' + packItem.SavingCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardLargessGoods.PackageCardProject.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length 
                          +saleOrderDetail.Product.length
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +item.PackageCardGoods.PackageCardGeneralCard.length
                          +item.PackageCardGoods.PackageCardProduct.length
                          +item.PackageCardLargessGoods.PackageCardProject.length
                          }} {{packItem.Name}}【赠】
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>

                      <div class="dis_flex" v-if="packItem.AccountLargessAmount > 0">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥{{(packItem.AccountLargessAmount || 0).toFixed(2) | NumFormat}}</span>
                      </div>

                      <div class="dis_flex" v-if="packItem.TotalPrice > 0">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款赠额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.TotalPrice | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡时效卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardLargessGoods.PackageCardTimeCard" :key="Pindex + 'PTimeCardID' + packItem.TimeCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardLargessGoods.PackageCardProject.length
                          +item.PackageCardLargessGoods.PackageCardSavingCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length 
                          +saleOrderDetail.Product.length
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +item.PackageCardGoods.PackageCardGeneralCard.length
                          +item.PackageCardGoods.PackageCardProduct.length
                          +item.PackageCardLargessGoods.PackageCardProject.length
                          +item.PackageCardLargessGoods.PackageCardSavingCard.length

                          }} {{packItem.Name}}【赠】
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.PackageQuantity}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{packItem.IsLargess?'0.00':packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡通用次卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardLargessGoods.PackageCardGeneralCard" :key="Pindex + 'PGeneralCardID' + packItem.GeneralCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardLargessGoods.PackageCardProject.length
                          +item.PackageCardLargessGoods.PackageCardSavingCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length 
                          +saleOrderDetail.Product.length
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +item.PackageCardGoods.PackageCardGeneralCard.length
                          +item.PackageCardGoods.PackageCardProduct.length
                          +item.PackageCardLargessGoods.PackageCardProject.length
                          +item.PackageCardLargessGoods.PackageCardSavingCard.length
                          +item.PackageCardLargessGoods.PackageCardTimeCard.length
                          }} {{packItem.Name}}【赠】
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠卡次数</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.AccountCardTimes}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退卡次数</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.CardTimes}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{packItem.IsLargess?'0.00':packItem.TotalAmount | NumFormat}}</span>
                      </div>

                    </div>

                    <!-- 套餐卡产品 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardLargessGoods.PackageCardProduct" :key="Pindex + 'PProduct' + packItem.ProductID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardLargessGoods.PackageCardProject.length
                          +item.PackageCardLargessGoods.PackageCardSavingCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length 
                          +saleOrderDetail.Product.length
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +item.PackageCardGoods.PackageCardGeneralCard.length
                          +item.PackageCardGoods.PackageCardProduct.length
                          +item.PackageCardLargessGoods.PackageCardProject.length
                          +item.PackageCardLargessGoods.PackageCardSavingCard.length
                          +item.PackageCardLargessGoods.PackageCardTimeCard.length
                          +item.PackageCardLargessGoods.PackageCardGeneralCard.length
                          }} {{packItem.Name}}【赠】
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.AccountQuantity}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;"> ¥ {{packItem.IsLargess?'0.00':packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                  </div>
                </template>
                <el-divider class="sell-el-divider"> </el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">合计</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{saleOrderDetail.TotalAmount | NumFormat}}</span>
                </div>
                <div v-if="saleOrderDetail.SaleBillPay && saleOrderDetail.SaleBillPay.length > 0 " class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">付款：</span>
                  <div class="flex_box">
                    <div class="dis_flex " v-for="pay in saleOrderDetail.SaleBillPay" :key="pay.ID + 'pay'">
                      <span class="flex_box color_gray line_height_24">{{pay.Name}}</span>
                      <span class="flex_box text_right line_height_24">¥ {{pay.Amount | NumFormat}}</span>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div v-if="saleOrderDetail.SaleBillPaySavingCardDeduction && saleOrderDetail.SaleBillPaySavingCardDeduction.length > 0" class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">卡抵扣：</span>
                  <div class="flex_box">
                    <div class="dis_flex " v-for="cardPay in saleOrderDetail.SaleBillPaySavingCardDeduction" :key="cardPay.ID + 'cardPay'">
                      <span class="flex_box color_gray line_height_24">{{cardPay.Name}}</span>
                      <span class="flex_box text_right line_height_24">¥ {{cardPay.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </div>
                <div class="dis_flex" v-if="saleOrderDetail.PricePreferentialAmount!=0  && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">手动改价</span>
                  <span class="flex_box font_12 text_right line_height_24" v-if="saleOrderDetail.PricePreferentialAmount>0">-￥{{saleOrderDetail.PricePreferentialAmount | NumFormat}}</span>
                  <span class="flex_box font_12 text_right line_height_24" v-else>+￥{{mathAbsData(saleOrderDetail.PricePreferentialAmount) | NumFormat}}</span>
                </div>
                <div class="dis_flex" v-if="saleOrderDetail.CardPreferentialAmount>0 && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">卡优惠</span>
                  <span class="flex_box font_12 text_right line_height_24">-￥{{saleOrderDetail.CardPreferentialAmount | NumFormat}}</span>
                </div>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex flex_dir_column font_14 font_weight_600 flex_y_center color_999 padbm_10">
                  <span>{{cashierReceipt.WriteTextFirst}}</span>
                  <span>{{cashierReceipt.WriteTextSecond}}</span>
                </div>

              </div>
            </el-scrollbar>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="refundCashierReceiptDialogVisible = false;" size="small" :disabled="modalLoading">取 消</el-button>
        <el-button type="primary" @click="printRefundSaleBillContent" :loading="modalLoading" v-prevent-click size="small">打印</el-button>
      </div>
    </el-dialog>

    <el-dialog title="新增客户" :visible.sync="isAddCustom" width="850px">
      <div>
        <el-form :model="customer" :rules="customerRoules" ref="customer" label-width="90px" size="small">
          <el-row>
            <el-col :span="12">
              <el-form-item label="会员姓名" prop="Name">
                <el-input v-model="customer.Name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号码" prop="PhoneNumber">
                <el-input v-model="customer.PhoneNumber" maxlength="11"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员编号">
                <el-input v-model="customer.Code"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员来源">
                <el-cascader v-model="customer.CustomerSourceID" :options="customerSource" :props="{
                    checkStrictly: true,
                    children: 'Child',
                    value: 'ID',
                    label: 'Name',
                    emitPath: false,
                  }" :show-all-levels="false" clearable filterable></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属顾问">
                <el-select v-model="customer.EmployeeID" placeholder="请选择所属顾问" clearable filterable size="small">
                  <el-option v-for="item in employee" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员等级">
                <el-select v-model="customer.CustomerLevelID" placeholder="请选择会员等级" clearable filterable size="small">
                  <el-option v-for="item in customerLevel" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员性别">
                <el-radio-group v-model="customer.Gender">
                  <el-radio label="2">女</el-radio>
                  <el-radio label="1">男</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer">
        <el-button size="small" @click="isAddCustom = false" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" @click="submitCustomer" :loading="modalLoading" v-prevent-click>保存并开单</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/iBeauty/Order/saleGoods";
import APIR from "@/api/iBeauty/RefundBill/refundStock";
import APICustomer from "@/api/CRM/Customer/customer";
import APICustomerLevel from "@/api/CRM/Customer/customerLevel";
import APICustomerSource from "@/api/CRM/Customer/customerSource";
import validate from "@/components/js/validate.js";
import date from "../../../components/js/date";
import customerTagLibraryVue from "../../CRM/Customer/customerTagLibrary.vue";
import cashierAPI from "@/api/iBeauty/Order/cashierReceipt";
import orderAPI from "@/api/iBeauty/Order/saleBill";
import printReceipt from "@/components/js/print";

var Enumerable = require("linq");
var socket;
export default {
  name: "RefundBillCreate",
  data() {
    return {
      refundCashierReceiptDialogVisible: false,
      printLoading: false,
      dialogSuccess: false,
      packageDetailVisible: false,
      loading: false,
      isAddCustom: false,
      modalLoading: false,
      createRefundLoading: false,
      submitRefundLoading: false,
      BillDate: date.formatDate.format(new Date(), "YYYY-MM-DD hh:mm:ss"),
      Checkout: false, //判断是否可以提交结账申请
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() > Date.now()
            // || time.getTime() < Date.now() - 3600 * 1000 * 24 * 7
          );
        },
      },
      IsSupplement: false,
      packageDetailLoading: false,
      customerName: "",
      customerID: null,
      customerFullName: "",
      customerPhoneNumber: "",
      customer: {
        Name: "",
        PhoneNumber: "",
        Gender: "2",
        CustomerSourceID: null,
        EmployeeID: "",
        CustomerLevelID: "",
        Code: "",
      },
      customerRoules: {
        Name: [{ required: true, message: "请输入客户名称", trigger: "blur" }],
        PhoneNumber: [
          {
            required: true,
            validator: validate.validPhoneNumber,
            trigger: "blur",
          },
        ],
        Gender: [
          { required: true, message: "请选择客户性别", trigger: "change" },
        ],
        CustomerSourceID: [
          { required: true, message: "请选择客户来源", trigger: "change" },
        ],
        EmployeeID: [
          { required: true, message: "请选择顾问", trigger: "change" },
        ],
        CustomerLevelID: [
          { required: true, message: "请选择客户等级", trigger: "change" },
        ],
        Code: [{ required: true, message: "请输入客户编号", trigger: "blur" }],
      },
      employee: [], //营销顾问
      customerLevel: [], //顾客等级
      customerSource: [], //顾客来源
      payTypeList: [], //全部的支付方式
      projectList: [], // 项目
      savingCardList: [], // 储值卡
      timeCardList: [], // 时效卡
      generalCardList: [], // 通用次卡
      packageCardList: [], // 套餐卡
      packageCardDetailList: "", // 套餐卡明细
      productList: [], // 产品
      tabPane: "",
      goodsName: "", // 搜索
      selectProject: [], // 项目
      selectSavingCard: [], // 储值卡
      selectTimeCard: [], // 时效卡
      selectGeneralCard: [], // 通用次卡
      selectProduct: [], // 产品
      selectPackageCard: [], // 套餐卡
      handlerList: [], //公共经手人列表
      productHandlerList: [], //产品经手人列表
      projectHandlerList: [], //项目经手人列表
      savingCardHandlerList: [], //储值卡经手人列表
      timeCardHandlerList: [], //时效卡经手人列表
      generalCardHandlerList: [], //通用次卡经手人列表
      packageCardHandlerList: [], //套餐卡经手人列表
      dialogVisible: false, // 经手人弹框
      handlerName: "", // 经手人搜索
      tabHandle: "0", // 经手人tabs
      currentSelectPackageItem: {}, //当前选中的套餐卡
      currentPackCategoryIndex: 0, //当前选中的套餐卡 Index
      PayAmount: 0,
      PayPrice: 0,
      savingCardGiftPrice: 0, //储值卡有效赠额
      dialogBill: false,
      Remark: "",
      entityName: "",
      userName: "",
      payInfo: {},
      payTotalPrice: 0,
      payList: [{ PayMethodID: "", Amount: "", price: 0 }],
      saleOrderDetail: "",
      cashierReceipt: "",
      billOrderNumber: "", // 订单编号
      refundTotalAmount_s: "",
      sucRemark: "",
    };
  },
  computed: {
    totalAmount() {
      var that = this;
      let refoundPrice = 0;
      let savingCardGiftPrice = 0;
      // that.selectProject.forEach(function (item) {
      //   refoundPrice += parseFloat(item.RefundPrice)?parseFloat(item.RefundPrice):0;
      // });
      refoundPrice += Enumerable.from(that.selectProject).sum((i) =>
        parseFloat(Number(i.RefundPrice))
      );
      // that.selectSavingCard.forEach(function (item) {
      //   refoundPrice += parseFloat(item.effectivePrice)?parseFloat(item.effectivePrice):0;
      //   savingCardGiftPrice += parseFloat(item.effectiveGiftPrice);
      // });
      refoundPrice += Enumerable.from(that.selectSavingCard).sum((i) =>
        parseFloat(Number(i.effectivePrice))
      );
      savingCardGiftPrice += Enumerable.from(that.selectSavingCard).sum((i) =>
        parseFloat(Number(i.effectiveGiftPrice))
      );
      // that.selectTimeCard.forEach(function (item) {
      //   refoundPrice += parseFloat(item.effectivePrice)?parseFloat(item.effectivePrice):0;
      // });
      refoundPrice += Enumerable.from(that.selectTimeCard).sum((i) =>
        parseFloat(Number(i.effectivePrice))
      );
      // that.selectGeneralCard.forEach(function (item) {
      //   refoundPrice += parseFloat(item.RefundPrice)?parseFloat(item.RefundPrice):0;
      // });
      refoundPrice += Enumerable.from(that.selectGeneralCard).sum((i) =>
        parseFloat(Number(i.RefundPrice))
      );
      // that.selectProduct.forEach(function (item) {
      //   refoundPrice += parseFloat(item.RefundPrice)?parseFloat(item.RefundPrice):0;
      // });

      refoundPrice += Enumerable.from(that.selectProduct).sum((i) =>
        parseFloat(Number(i.RefundPrice))
      );

      that.selectPackageCard.forEach(function (item) {
        refoundPrice += Enumerable.from(item.Project).sum((i) =>
          parseFloat(Number(i.effectivePrice))
        );
        refoundPrice += Enumerable.from(item.Product).sum((i) =>
          parseFloat(Number(i.effectivePrice))
        );
        refoundPrice += Enumerable.from(item.SavingCard).sum((i) =>
          parseFloat(Number(i.effectivePrice))
        );
        savingCardGiftPrice += Enumerable.from(item.SavingCard).sum((i) =>
          parseFloat(Number(i.effectiveGiftPrice))
        );

        refoundPrice += Enumerable.from(item.TimeCard).sum((i) =>
          parseFloat(Number(i.effectivePrice))
        );
        refoundPrice += Enumerable.from(item.GeneralCard).sum((i) =>
          parseFloat(Number(i.genAmount))
        );

        // if (item.ChildItems.length > 0) {
        //   item.ChildItems.forEach((s) => {
        //     if (s.type_name == "项目") {
        //       refoundPrice += parseFloat(s.effectivePrice)?parseFloat(s.effectivePrice):0;
        //     } else if (s.type_name == "储值卡") {
        //       refoundPrice += parseFloat(s.effectivePrice)?parseFloat(s.effectivePrice):0;
        //       savingCardGiftPrice += parseFloat(s.effectiveGiftPrice)?parseFloat(s.effectiveGiftPrice):0;
        //     } else if (s.type_name == "时效卡") {
        //       refoundPrice += parseFloat(s.effectivePrice)?parseFloat(s.effectivePrice):0;
        //     } else if (s.type_name == "通用次卡") {
        //       refoundPrice += parseFloat(s.genAmount)?parseFloat(s.genAmount):0;
        //     } else {
        //       refoundPrice += parseFloat(s.effectivePrice)?parseFloat(s.effectivePrice):0;
        //     }
        //   });
        // }
      });

      return refoundPrice;
    },
    PricePreferentialAmount() {
      var that = this;
      let pricePreferentialAmount = 0;
      let savingCardGiftPrice = 0;
      that.selectProject.forEach(function (item) {
        pricePreferentialAmount += parseFloat(item.PricePreferentialAmount)
          ? parseFloat(item.PricePreferentialAmount)
          : 0;
      });
      // that.selectSavingCard.forEach(function (item) {
      //   pricePreferentialAmount += parseFloat(item.PricePreferentialAmount)?parseFloat(item.PricePreferentialAmount):0;
      //   savingCardGiftPrice += parseFloat(item.PricePreferentialAmount);
      // });
      that.selectTimeCard.forEach(function (item) {
        pricePreferentialAmount += parseFloat(item.PricePreferentialAmount)
          ? parseFloat(item.PricePreferentialAmount)
          : 0;
      });
      that.selectGeneralCard.forEach(function (item) {
        pricePreferentialAmount += parseFloat(item.PricePreferentialAmount)
          ? parseFloat(item.PricePreferentialAmount)
          : 0;
      });
      that.selectPackageCard.forEach(function (item) {
        pricePreferentialAmount = Enumerable.from(item.Project).sum((i) =>
          parseFloat(i.PricePreferentialAmount)
        );
        pricePreferentialAmount = Enumerable.from(item.TimeCard).sum((i) =>
          parseFloat(i.PricePreferentialAmount)
        );
        pricePreferentialAmount = Enumerable.from(item.GeneralCard).sum((i) =>
          parseFloat(i.PricePreferentialAmount)
        );
        pricePreferentialAmount = Enumerable.from(item.Product).sum((i) =>
          parseFloat(i.PricePreferentialAmount)
        );

        // if (item.ChildItems.length > 0) {
        //   item.ChildItems.forEach((s) => {
        //     if (s.type_name == "项目") {
        //       pricePreferentialAmount += parseFloat(s.PricePreferentialAmount)?parseFloat(s.PricePreferentialAmount):0;
        //     } else if (s.type_name == "储值卡") {
        //       // pricePreferentialAmount += parseFloat(s.PricePreferentialAmount)?parseFloat(s.PricePreferentialAmount):0;
        //       // savingCardGiftPrice += parseFloat(s.effectiveGiftPrice)?parseFloat(s.effectiveGiftPrice):0;
        //     } else if (s.type_name == "时效卡") {
        //       pricePreferentialAmount += parseFloat(s.PricePreferentialAmount)?parseFloat(s.PricePreferentialAmount):0;
        //     } else if (s.type_name == "通用次卡") {
        //       pricePreferentialAmount += parseFloat(s.PricePreferentialAmount)?parseFloat(s.PricePreferentialAmount):0;
        //     } else {
        //       pricePreferentialAmount += parseFloat(s.PricePreferentialAmount)?parseFloat(s.PricePreferentialAmount):0;
        //     }
        //   });
        // }
      });
      that.selectProduct.forEach(function (item) {
        pricePreferentialAmount += parseFloat(item.PricePreferentialAmount)
          ? parseFloat(item.PricePreferentialAmount)
          : 0;
      });
      return pricePreferentialAmount;
    },
    /**  计算支付总金额  */
    payMethodTotalAmount() {
      var that = this;
      var payTotalAmount = 0;
      payTotalAmount = Enumerable.from(that.payList).sum((i) =>
        Number(i.Amount)
      );
      return parseFloat(payTotalAmount.toFixed(2));
    },
    /**  储值卡抵扣金额  */
    savingCardDeductionTotalAmount() {
      var that = this;
      var deductionTotalAmount = 0;

      deductionTotalAmount = Enumerable.from(
        that.payInfo.SavingCardDeduction
      ).sum((i) => Number(i.Amount) + Number(i.LargessAmount));
      return parseFloat(Number(deductionTotalAmount.toFixed(2)));
    },
  },
  methods: {
    /**    */
    showPackageDetail() {
      console.log("显示套餐卡详情");
    },
    // 顾客
    saleCustomerData: function (queryString, cb) {
      var that = this;
      that.loading = true;
      var params = {
        Name: queryString ? queryString : "",
      };
      API.getSaleCustomer(params)
        .then((res) => {
          if (res.StateCode == 200) {
            cb(res.Data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  选择顾客  */
    handleCustomerSelect(item) {
      var that = this;
      if (item.ID != undefined) {
        that.tabPane = "";
        that.customerID = item.ID;
        that.customerFullName = item.Name;
        that.customerPhoneNumber = item.PhoneNumber;
        that.customerName = item.Name + "【" + item.PhoneNumber + "】";
        that.getStockNetwork();
      } else {
        // that.addNewCustomer();
      }
    },
    /**  删除会员  */
    removeCustomer() {
      var that = this;
      that.customerID = null;
      that.customerFullName = "";
      that.customerPhoneNumber = "";
      that.customerName = "";
      that.huiyuanCheck();
    },
    /**  会员数据  */
    mathAbsData: function (item) {
      return Math.abs(item);
    },

    // 新增顾客
    addNewCustomer: function () {
      var that = this;
      that.ruleForm = {
        Name: "",
        PhoneNumber: "",
        Gender: "2",
        CustomerSourceID: null,
        EmployeeID: "",
        CustomerLevelID: "",
        Code: "",
      };
      that.isAddCustom = true;
      that.employeeData();
    },
    // 保存新增客户
    submitCustomer: function () {
      var that = this;
      this.$refs.customer.validate((valid) => {
        if (valid) {
          that.modalLoading = true;
          let para = Object.assign({}, that.customer);
          APICustomer.createCustomer(para)
            .then(function (res) {
              if (res.StateCode === 200) {
                that.$message.success({
                  message: "新增成功",
                  duration: 2000,
                });
                that.customerID = res.Data.ID;
                that.customerFullName = res.Data.Name;
                that.customerPhoneNumber = res.Data.PhoneNumber;
                that.customerName =
                  res.Data.Name + "【" + res.Data.PhoneNumber + "】";
                that.handleClick();
                that.$refs["customer"].resetFields();
                that.isAddCustom = false;
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {
              that.modalLoading = false;
            });
        }
      });
    },
    //客户所属顾问
    employeeData: function () {
      var that = this;
      APICustomer.getConsultant()
        .then((res) => {
          if (res.StateCode == 200) {
            that.employee = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 顾客来源
    CustomerSourceData: function () {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      APICustomerSource.getCustomerSource(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.customerSource = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 顾客等级
    CustomerLevelData: function () {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      APICustomerLevel.getCustomerLevel(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.customerLevel = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //获取开单时间
    getBillDate: function () {
      var that = this;
      return that.isSupplement
        ? that.BillDate
        : date.formatDate.format(new Date(), "YYYY-MM-DD hh:mm:ss");
    },
    /**    */
    getStockNetwork() {
      var that = this;
      that.projectData();
      that.productData();
      that.savingCardData();
      that.timeCardData();
      that.generalCardData();
      that.packageCardData();
    },
    // 搜索商品
    searchGoodsClick: function () {
      var that = this;
      if (that.customerID == "" || that.customerID == undefined) {
        that.$message.error({
          message: "顾客编号不能为空",
          duration: 1000,
        });
      } else {
        that.tabPane = "";
        that.getStockNetwork();
      }
    },
    // 清空
    clearClick() {
      var that = this;
      that.searchGoodsClick();
    },

    //收款方式改变
    payMethodChange: function (item) {
      var that = this;
      if (item.PayMethodID == "") {
        item.Amount = "";
      } else {
        if (item.Amount == "") {
          item.Amount = parseFloat(that.PayPrice).toFixed(2);
        }
      }
      that.payPriceChange(item);
    },
    //删除
    removePayClick(index) {
      var that = this;
      that.payList.splice(index, 1);
      that.paymentAmountData();
    },
    //价格改变
    payPriceChange(item) {
      var that = this;
      var PayAmount = (
        parseFloat(that.PayPrice) + parseFloat(item.price)
      ).toFixed(2);
      if (parseFloat(item.Amount) > parseFloat(PayAmount)) {
        item.Amount = PayAmount;
      }
      item.price = item.Amount;
      that.paymentAmountData();
    },
    //添加收款
    addPayclick() {
      var that = this;
      that.payList.push({ PayMethodID: "", Amount: "", price: 0 });
    },

    // 支付方式金额总计
    paymentAmountData: function () {
      var that = this;
      var amount = 0;
      that.payList.forEach(function (item) {
        amount = (parseFloat(amount) + (parseFloat(item.price) || 0)).toFixed(
          2
        );
      });
      that.payTotalPrice = amount;
      that.PayPrice = that.payInfo.PayAmount - that.payTotalPrice;
    },
    /**  点击switch切换  将搜索内容 置空   */
    handleClick() {
      var that = this;
      // that.goodsName = "";
    },

    //套餐卡下项目数量改变
    handleItemNumberChange(item, name) {
      var that = this;
      if (name == "产品" || name == "项目") {
        if (!item.IsLargess) {
          if (item.number == item.Balance) {
            item.effectivePrice = item.PerformanceBalanceAmount;
          } else {
            item.effectivePrice = (item.number * item.Amount).toFixed(2);
          }
        } else {
          item.effectivePrice = 0;
        }
        item.PricePreferentialAmount = 0;
      }
      if (name == "通用次卡") {
        if (item.IsLargess) {
          item.genAmount = 0;
        } else {
          if (item.number == item.Balance) {
            item.genAmount = item.PerformanceBalanceAmount;
          } else {
            item.genAmount = (item.number * item.Amount).toFixed(2);
          }
        }
        item.PricePreferentialAmount = 0;
      }
    },

    //删除套餐卡下的项目
    removeSelectItemClick(packItem, packageIndex, index, type) {
      var that = this;
      switch (type) {
        case 0: //项目
          packItem.Project.splice(index, 1);
          break;
        case 1: // 储值卡
          packItem.SavingCard.splice(index, 1);
          break;
        case 2: // 通用次卡
          packItem.GeneralCard.splice(index, 1);
          break;
        case 3: // 时效卡
          packItem.TimeCard.splice(index, 1);
          break;
        case 4: //产品
          packItem.Product.splice(index, 1);
          break;

        default:
          break;
      }
      if (
        packItem.Project.length == 0 &&
        packItem.SavingCard.length == 0 &&
        packItem.GeneralCard.length == 0 &&
        packItem.TimeCard.length == 0 &&
        packItem.Product.length == 0
      ) {
        that.selectPackageCard.splice(packageIndex, 1);
      }

      // if (that.selectPackageCard[lastIndex].ChildItems.length == 1) {
      //   that.selectPackageCard.splice(lastIndex, 1);
      // } else {
      //   that.selectPackageCard[lastIndex].ChildItems.splice(index, 1);
      // }
    },

    /**  套餐卡分类点击事件  */
    clickPackageCagegory(item, index) {
      var that = this;
      that.currentPackCategoryIndex = index;
      that.currentSelectPackageItem = item;
      that.packageCardDetailList = "";
      that.packageDetailVisible = true;
      that.getPackageCardAccountDetails();
    },
    /** 修改全退状态   */
    allRefundChangeClick(evn, row) {
      if (evn) {
        row.number = row.Balance;
        row.RefundPrice = row.PerformanceBalanceAmount;
      } else {
        row.number = row.ValidBalance;
        row.RefundPrice = parseFloat(row.number) * parseFloat(row.Amount);
      }
    },
    /**  套餐卡中的 品项 全退修改  */
    allPackageRefundChangeClick(evn, row, type) {
      if (type == 1) {
        //项目产品
        if (evn) {
          row.number = row.Balance;
          row.effectivePrice = row.PerformanceBalanceAmount;
        } else {
          row.number = row.ValidBalance;
          row.effectivePrice = parseFloat(row.number) * parseFloat(row.Amount);
        }
      }

      if (type == 2) {
        // 通用次卡
        if (evn) {
          row.number = row.Balance;
          row.genAmount = row.PerformanceBalanceAmount;
        } else {
          row.number = row.ValidBalance;
          row.genAmount = parseFloat(row.number) * parseFloat(row.Amount);
        }
      }
    },

    /**  自定义 存量 body 样式  */
    customCardBody_style(item) {
      if (item.RefundBalance > 0)
        return { padding: "0px", "background-color": "#F5F7FA" };
      return { padding: "0px" };
    },
    /** 动态修改鼠标移入效果   */
    customShadow(item) {
      if (item.RefundBalance > 0) return "never";
      return "hover";
    },

    // 选择项目
    selectItem(item) {
      var that = this;
      var tabPane = that.tabPane;
      switch (tabPane) {
        case "0":
          if (item.RefundBalance > 0) return;
          if (
            !Enumerable.from(that.selectProject).contains(item.ID, (i) => i.ID)
          ) {
            that.projectChange(item);
          } else {
            that.$message.error({
              message: "项目已存在",
              duration: 2000,
            });
            return;
          }
          break;
        case "1":
          if (that.selectSavingCard.length <= 0) {
            that.savingCardChange(item);
          } else {
            let flag = that.selectSavingCard.findIndex((p) => p.ID == item.ID);
            if (flag == -1) {
              that.savingCardChange(item);
            }
          }
          break;
        case "2":
          if (item.IsRefund) return;
          if (
            !Enumerable.from(that.selectTimeCard).contains(item.ID, (i) => i.ID)
          ) {
            that.timeCardChange(item);
          } else {
            that.$message.error({
              message: "退款时效卡已存在",
              duration: 2000,
            });
            return;
          }
          break;
        case "3":
          if (item.RefundAmount > 0) return;

          if (
            !Enumerable.from(that.selectGeneralCard).contains(
              item.ID,
              (i) => i.ID
            )
          ) {
            that.generalCardChange(item);
          } else {
            that.$message.error({
              message: "退款通用次卡已存在",
              duration: 2000,
            });
            return;
          }
          break;
        case "4":
          if (that.selectPackageCard.length <= 0) {
            that.packageCardChange(item);
          } else {
            //重复不添加
            let flag = true;
            that.selectPackageCard.forEach((p) => {
              if (p.ID == that.currentSelectPackageItem.ID) {
                p.ChildItems.forEach((s) => {
                  if (s.ID == item.ID) {
                    flag = false;
                  }
                });
              }
            });
            if (flag) {
              that.packageCardChange(item);
            } else {
              that.$message.error({
                message: "退款品项已存在",
                duration: 2000,
              });
            }
          }
          break;
        case "5":
          if (item.RefundBalance > 0) return;
          if (
            !Enumerable.from(that.selectProduct).contains(item.ID, (i) => i.ID)
          ) {
            that.productChange(item);
          } else {
            that.$message.error({
              message: "退款产品已存在",
              duration: 2000,
            });
            return;
          }
          break;
      }
    },

    /** 选择 套餐卡 中的 品项
     * 0项目 1 储值卡 2时效卡 3 通用次卡 4产品
     */
    selectPackageDetailItem(item, type) {
      let that = this;
      let packageItem = Enumerable.from(that.selectPackageCard).firstOrDefault(
        (i) => {
          return i.ID == that.currentSelectPackageItem.ID;
        },
        -1
      );
      let flag = false;
      if (packageItem == -1) {
        flag = true;
        packageItem = {
          ID: that.currentSelectPackageItem.ID,
          PackageCardID: that.currentSelectPackageItem.PackageCardID,
          Name: that.currentSelectPackageItem.Name,
          IsLargess: that.currentSelectPackageItem.IsLargess,
          Project: [],
          Product: [],
          SavingCard: [],
          GeneralCard: [],
          TimeCard: [],
          handleTypeList: [],
        };
        packageItem.handleTypeList = Enumerable.from(
          that.packageCardHandlerList
        )
          .select((i) => ({
            ID: i.ID,
            Name: i.Name,
            Employee: [],
          }))
          .toArray();
      }

      switch (type) {
        case 0: // 项目
          if (item.RefundBalance > 0) return;
          if (item.Balance <= 0) {
            that.$message.error({
              message: "剩余次数不足",
              duration: 2000,
            });
            return;
          }
          let PackageProjectItem = Enumerable.from(
            packageItem.Project
          ).firstOrDefault((i) => {
            return i.ID == item.ID;
          }, -1);
          if (PackageProjectItem == -1) {
            packageItem.Project.push({
              allRefund: true,
              number: item.Balance,
              effectivePrice:
                item.IsLargess == true ? 0 : item.PerformanceBalanceAmount,
              Alias: item.Alias,
              Amount: item.Amount,
              ArrearAmount: item.ArrearAmount,
              Balance: item.Balance,
              BuyDate: item.BuyDate,
              ID: item.ID,
              IsLargess: item.IsLargess,
              Name: item.Name,
              Price: item.Price,
              ProjectID: item.ProjectID,
              Quantity: item.Quantity,
              RefundBalance: item.RefundBalance,
              TotalAmount: item.TotalAmount,
              ValidBalance: item.ValidBalance,
              type_name: item.type_name,
              PricePreferentialAmount: 0,
              PerformanceBalanceAmount: item.PerformanceBalanceAmount,
            });
          } else {
            that.$message.error({
              message: "套餐卡中项目已存在",
              duration: 2000,
            });
            return;
          }
          break;
        case 1: // 储值卡
          if (item.ValidBalance <= 0) {
            that.$message.error({
              message: "有效余额不足",
              duration: 2000,
            });
            return;
          }
          let PackageSavingCardItem = Enumerable.from(
            packageItem.SavingCard
          ).firstOrDefault((i) => {
            return i.ID == item.ID;
          }, -1);
          if (PackageSavingCardItem == -1) {
            packageItem.SavingCard.push({
              number: 1,
              effectiveGiftPrice: item.AllowRefundLargessBalance,
              effectivePrice: item.AllowRefundBalance,
              AllowRefundLargessBalance: item.AllowRefundLargessBalance,
              AllowRefundBalance: item.AllowRefundBalance,
              AccountID: item.AccountID,
              Alias: item.Alias,
              Amount: item.Amount,
              ArrearAmount: item.ArrearAmount,
              Balance: item.Balance,
              BuyDate: item.BuyDate,
              ID: item.ID,
              LargessBalance: item.LargessBalance,
              Name: item.Name,
              RefundAmount: item.RefundAmount,
              SavingCardID: item.SavingCardID,
              TotalBalance: item.TotalBalance,
              Type: item.Type,
              ValidBalance: item.ValidBalance,
              ValidDayName: item.ValidDayName,
              type_name: item.type_name,
            });
          } else {
            that.$message.error({
              message: "套餐卡中储值卡已存在",
              duration: 2000,
            });
            return;
          }
          break;
        case 2: // 时效卡
          if (item.IsRefund) return;
          if (
            item.CycleLimitAmount - item.ConsumeAmount <= 0 &&
            item.ConsumeCycle != 0 &&
            item.CycleLimitAmount != 0
          ) {
            that.$message.error({
              message: "周期内有效次数不足",
              duration: 2000,
            });
            return;
          }
          let PackageTimeCardItem = Enumerable.from(
            packageItem.TimeCard
          ).firstOrDefault((i) => {
            return i.ID == item.ID;
          }, -1);
          if (PackageTimeCardItem == -1) {
            packageItem.TimeCard.push({
              number: 1,
              effectivePrice: item.IsLargess == true ? 0 : item.TotalAmount,
              Alias: item.Alias,
              Amount: item.Amount,
              ArrearAmount: item.ArrearAmount,
              BuyDate: item.BuyDate,
              ConsumeAmount: item.ConsumeAmount,
              ConsumeCycle: item.ConsumeCycle,
              ConsumeCycleAmount: item.ConsumeCycleAmount,
              CycleLimitAmount: item.CycleLimitAmount,
              ID: item.ID,
              IsLargess: item.IsLargess,
              Name: item.Name,
              PerformanceBalance: item.PerformanceBalance,
              PerformanceBalanceAmount: item.PerformanceBalanceAmount,
              PerformanceTimes: item.PerformanceTimes,
              Price: item.Price,
              TimeCardID: item.TimeCardID,
              TotalAmount: item.TotalAmount,
              ValidBalance: item.ValidBalance,
              ValidDayName: item.ValidDayName,
              type_name: item.type_name,
              PricePreferentialAmount: 0,
            });
          } else {
            that.$message.error({
              message: "套餐卡中时效卡已存在",
              duration: 2000,
            });
            return;
          }

          break;
        case 3: // 通用次卡
          if (item.RefundAmount > 0) return;
          if (item.Balance <= 0) {
            that.$message.error({
              message: "剩余次数不足",
              duration: 2000,
            });
            return;
          }
          let PackageGeneralCardItem = Enumerable.from(
            packageItem.GeneralCard
          ).firstOrDefault((i) => {
            return i.ID == item.ID;
          }, -1);
          if (PackageGeneralCardItem == -1) {
            packageItem.GeneralCard.push({
              allRefund: true,
              number: item.Balance,
              genAmount:
                item.IsLargess == true ? 0 : item.PerformanceBalanceAmount,
              Alias: item.Alias,
              Amount: item.Amount,
              ArrearAmount: item.ArrearAmount,
              Balance: item.Balance,
              BuyDate: item.BuyDate,
              CardTimes: item.CardTimes,
              GeneralCardID: item.GeneralCardID,
              ID: item.ID,
              IsLargess: item.IsLargess,
              Name: item.Name,
              Price: item.Price,
              RefundAmount: item.RefundAmount,
              TotalAmount: item.TotalAmount,
              ValidBalance: item.ValidBalance,
              ValidDayName: item.ValidDayName,
              type_name: item.type_name,
              PricePreferentialAmount: 0,
              PerformanceBalanceAmount: item.PerformanceBalanceAmount,
            });
          } else {
            that.$message.error({
              message: "套餐卡中通用次卡已存在",
              duration: 2000,
            });
            return;
          }

          break;
        case 4: // 产品
          if (item.RefundBalance > 0) return;
          if (item.Balance <= 0) {
            that.$message.error({
              message: "剩余次数不足",
              duration: 2000,
            });
            return;
          }
          let PackageProductItem = Enumerable.from(
            packageItem.Product
          ).firstOrDefault((i) => {
            return i.ID == item.ID;
          }, -1);
          if (PackageProductItem == -1) {
            packageItem.Product.push({
              allRefund: true,
              number: item.Balance,
              effectivePrice:
                item.IsLargess == true ? 0 : item.PerformanceBalanceAmount,
              Alias: item.Alias,
              Amount: item.Amount,
              ArrearAmount: item.ArrearAmount,
              Balance: item.Balance,
              BuyDate: item.BuyDate,
              ID: item.ID,
              IsLargess: item.IsLargess,
              Name: item.Name,
              Price: item.Price,
              ProductID: item.ProductID,
              Quantity: item.Quantity,
              RefundBalance: item.RefundBalance,
              TotalAmount: item.TotalAmount,
              ValidBalance: item.ValidBalance,
              type_name: item.type_name,
              PricePreferentialAmount: 0,
              PerformanceBalanceAmount: item.PerformanceBalanceAmount,
            });
          } else {
            that.$message.error({
              message: "套餐卡中产品已存在",
              duration: 2000,
            });
            return;
          }

          break;
      }
      if (flag) {
        that.selectPackageCard.push(packageItem);
      }
    },

    // 套餐卡选择+=============================
    packageCardChange: function (item) {
      var that = this;
      switch (item.type_name) {
        case "项目":
          if (item.ValidBalance <= 0) {
            that.$message.error({
              message: "有效次数不足",
              duration: 2000,
            });
            return;
          }

          let packProjects = {
            number: 1,
            effectivePrice: item.IsLargess == true ? 0 : item.Amount,
            Alias: item.Alias,
            Amount: item.Amount,
            ArrearAmount: item.ArrearAmount,
            Balance: item.Balance,
            BuyDate: item.BuyDate,
            ID: item.ID,
            IsLargess: item.IsLargess,
            Name: item.Name,
            Price: item.Price,
            ProjectID: item.ProjectID,
            Quantity: item.Quantity,
            RefundBalance: item.RefundBalance,
            TotalAmount: item.TotalAmount,
            ValidBalance: item.ValidBalance,
            type_name: item.type_name,
            PricePreferentialAmount: 0,
          };
          var packageItem = null;
          that.selectPackageCard.forEach((packItem) => {
            if (packItem.ID == that.currentSelectPackageItem.ID) {
              packageItem = packItem;
            }
          });
          if (packageItem == null) {
            packageItem = {
              ID: that.currentSelectPackageItem.ID,
              PackageCardID: that.currentSelectPackageItem.PackageCardID,
              Name: that.currentSelectPackageItem.Name,
              IsLargess: that.currentSelectPackageItem.IsLargess,
              ChildItems: [packProjects],
            };
            that.selectPackageCard.push(packageItem);
          } else {
            packageItem.ChildItems.push(packProjects);
          }

          break;
        case "产品":
          if (item.ValidBalance <= 0) {
            that.$message.error({
              message: "有效次数不足",
              duration: 2000,
            });
            return;
          }
          let packProducts = {
            number: 1,
            effectivePrice: item.IsLargess == true ? 0 : item.Amount,
            Alias: item.Alias,
            Amount: item.Amount,
            ArrearAmount: item.ArrearAmount,
            Balance: item.Balance,
            BuyDate: item.BuyDate,
            ID: item.ID,
            IsLargess: item.IsLargess,
            Name: item.Name,
            Price: item.Price,
            ProductID: item.ProductID,
            Quantity: item.Quantity,
            RefundBalance: item.RefundBalance,
            TotalAmount: item.TotalAmount,
            ValidBalance: item.ValidBalance,
            type_name: item.type_name,
            PricePreferentialAmount: 0,
          };
          var packageProdctItem = null;
          that.selectPackageCard.forEach((packItem) => {
            if (packItem.ID == that.currentSelectPackageItem.ID) {
              packageProdctItem = packItem;
            }
          });
          if (packageProdctItem == null) {
            packageProdctItem = {
              ID: that.currentSelectPackageItem.ID,
              Name: that.currentSelectPackageItem.Name,
              ChildItems: [packProducts],
            };
            that.selectPackageCard.push(packageProdctItem);
          } else {
            packageProdctItem.ChildItems.push(packProducts);
          }

          break;
        case "储值卡":
          if (item.ValidBalance <= 0) {
            that.$message.error({
              message: "有效余额不足",
              duration: 2000,
            });
            return;
          }
          let savingChildItem = {
            number: 1,
            effectiveGiftPrice: item.AllowRefundLargessBalance,
            effectivePrice: item.AllowRefundBalance,
            AllowRefundLargessBalance: item.AllowRefundLargessBalance,
            AllowRefundBalance: item.AllowRefundBalance,
            AccountID: item.AccountID,
            Alias: item.Alias,
            Amount: item.Amount,
            ArrearAmount: item.ArrearAmount,
            Balance: item.Balance,
            BuyDate: item.BuyDate,
            ID: item.ID,
            LargessBalance: item.LargessBalance,
            Name: item.Name,
            RefundAmount: item.RefundAmount,
            SavingCardID: item.SavingCardID,
            TotalBalance: item.TotalBalance,
            Type: item.Type,
            ValidBalance: item.ValidBalance,
            ValidDayName: item.ValidDayName,
            type_name: item.type_name,
          };
          var savingCardItem = null;
          that.selectPackageCard.forEach((item) => {
            if (item.ID == that.currentSelectPackageItem.ID) {
              savingCardItem = item;
            }
          });
          if (savingCardItem == null) {
            savingCardItem = {
              ID: that.currentSelectPackageItem.ID,
              Name: that.currentSelectPackageItem.Name,
              ChildItems: [savingChildItem],
            };
            that.selectPackageCard.push(savingCardItem);
          } else {
            savingCardItem.ChildItems.push(savingChildItem);
          }

          break;
        case "时效卡":
          if (
            item.CycleLimitAmount - item.ConsumeAmount <= 0 &&
            item.ConsumeCycle != 0 &&
            item.CycleLimitAmount != 0
          ) {
            that.$message.error({
              message: "周期内有效次数不足",
              duration: 2000,
            });
            return;
          }
          let packTimeCard = {
            number: 1,
            effectivePrice: item.IsLargess == true ? 0 : item.TotalAmount,
            Alias: item.Alias,
            Amount: item.Amount,
            ArrearAmount: item.ArrearAmount,
            BuyDate: item.BuyDate,
            ConsumeAmount: item.ConsumeAmount,
            ConsumeCycle: item.ConsumeCycle,
            ConsumeCycleAmount: item.ConsumeCycleAmount,
            CycleLimitAmount: item.CycleLimitAmount,
            ID: item.ID,
            IsLargess: item.IsLargess,
            Name: item.Name,
            PerformanceBalance: item.PerformanceBalance,
            PerformanceBalanceAmount: item.PerformanceBalanceAmount,
            PerformanceTimes: item.PerformanceTimes,
            Price: item.Price,
            TimeCardID: item.TimeCardID,
            TotalAmount: item.TotalAmount,
            ValidBalance: item.ValidBalance,
            ValidDayName: item.ValidDayName,
            type_name: item.type_name,
            PricePreferentialAmount: 0,
          };

          var timeCardItem = null;
          that.selectPackageCard.forEach((item) => {
            if (item.ID == that.currentSelectPackageItem.ID) {
              timeCardItem = item;
            }
          });
          if (timeCardItem == null) {
            timeCardItem = {
              ID: that.currentSelectPackageItem.ID,
              Name: that.currentSelectPackageItem.Name,
              ChildItems: [packTimeCard],
            };
            that.selectPackageCard.push(timeCardItem);
          } else {
            timeCardItem.ChildItems.push(packTimeCard);
          }

          break;
        case "通用次卡":
          if (item.ValidBalance <= 0) {
            that.$message.error({
              message: "有效次数不足",
              duration: 2000,
            });
            return;
          }

          let packGeneralCard = {
            number: 1,
            genAmount: item.IsLargess == true ? 0 : item.Amount,
            Alias: item.Alias,
            Amount: item.Amount,
            ArrearAmount: item.ArrearAmount,
            Balance: item.Balance,
            BuyDate: item.BuyDate,
            CardTimes: item.CardTimes,
            GeneralCardID: item.GeneralCardID,
            ID: item.ID,
            IsLargess: item.IsLargess,
            Name: item.Name,
            Price: item.Price,
            RefundAmount: item.RefundAmount,
            TotalAmount: item.TotalAmount,
            ValidBalance: item.ValidBalance,
            ValidDayName: item.ValidDayName,
            type_name: item.type_name,
            PricePreferentialAmount: 0,
          };
          var generalCardItem = null;
          that.selectPackageCard.forEach((item) => {
            if (item.ID == that.currentSelectPackageItem.ID) {
              generalCardItem = item;
            }
          });
          if (generalCardItem == null) {
            generalCardItem = {
              ID: that.currentSelectPackageItem.ID,
              Name: that.currentSelectPackageItem.Name,
              ChildItems: [packGeneralCard],
            };
            that.selectPackageCard.push(generalCardItem);
          } else {
            generalCardItem.ChildItems.push(packGeneralCard);
          }

          break;
      }

      that.selectPackageCard.forEach((p) => {
        p.handleTypeList = [];
        that.packageCardHandlerList.forEach((s) => {
          p.handleTypeList.push({
            ID: s.ID,
            Name: s.Name,
            Employee: [],
          });
        });
      });
    },
    /**  全选套餐卡  */
    selectAllPackageItem(item) {
      let that = this;
      if (item.IsFullRefund) return;
      that.currentSelectPackageItem = item;
      let packageItem = Enumerable.from(that.selectPackageCard).firstOrDefault(
        (i) => {
          return i.ID == item.ID;
        },
        -1
      );
      if (packageItem != -1) {
        this.$confirm("此套餐卡已存在, 是否重置套餐卡选中项?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            that.loading = true;
            let index = that.selectPackageCard.indexOf(packageItem);
            that.selectPackageCard.splice(index, 1);
            that.getPackageCardAccountDetails(true);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      } else {
        that.loading = true;
        that.getPackageCardAccountDetails(true);
      }
    },
    /**  删除选中的套餐卡   */
    removeSelectPackageItemClick(item, index) {
      let that = this;
      that.selectPackageCard.splice(index, 1);
    },

    // 数量改变
    numberChange(row, type) {
      var that = this;
      if (type) {
        if (row.IsLargess) {
          row.RefundPrice = 0;
        } else {
          if (row.number == row.Balance) {
            row.RefundPrice = row.PerformanceBalanceAmount;
          } else {
            row.RefundPrice = Number((row.number * row.Amount).toFixed(2));
          }
          row.PricePreferentialAmount = 0;
        }
      }
    },

    // 项目选择
    projectChange: function (row) {
      var that = this;
      if (row.Balance <= 0) {
        that.$message.error({
          message: "有效次数不足",
          duration: 2000,
        });
        return;
      }
      var project = {
        type: 0,
        allRefund: true,
        number: row.Balance,
        RefundPrice: row.IsLargess == true ? 0 : row.PerformanceBalanceAmount,
        PricePreferentialAmount: 0,
        ID: row.ID,
        ProjectID: row.ProjectID,
        Name: row.Name,
        Alias: row.Alias,
        ValidBalance: row.ValidBalance,
        RefundBalance: row.RefundBalance,
        ArrearAmount: row.ArrearAmount,
        TotalAmount: row.TotalAmount,
        Amount: row.Amount,
        Quantity: row.Quantity,
        Balance: row.Balance,
        Price: row.Price,
        IsLargess: row.IsLargess,
        BuyDate: row.BuyDate,
        PerformanceBalanceAmount: row.PerformanceBalanceAmount,
        handleTypeList: [],
      };
      project.handleTypeList = Enumerable.from(that.projectHandlerList).select(
        (item) => ({
          ID: item.ID,
          Name: item.Name,
          Employee: [],
        })
      );
      that.selectProject.push(project);
    },

    // 产品选择
    productChange: function (row) {
      var that = this;
      if (row.Balance <= 0) {
        that.$message.error({
          message: "有效次数不足",
          duration: 2000,
        });
        return;
      }

      var data = {
        type: 1,
        number: row.Balance,
        allRefund: true,
        RefundPrice: row.IsLargess == true ? 0 : row.PerformanceBalanceAmount,
        PricePreferentialAmount: 0,
        handleTypeList: [],
        ID: row.ID,
        ProductID: row.ProductID,
        Name: row.Name,
        Alias: row.Alias,
        ValidBalance: row.ValidBalance,
        RefundBalance: row.RefundBalance,
        ArrearAmount: row.ArrearAmount,
        TotalAmount: row.TotalAmount,
        Amount: row.Amount,
        Quantity: row.Quantity,
        Balance: row.Balance,
        Price: row.Price,
        IsLargess: row.IsLargess,
        PerformanceBalanceAmount: row.PerformanceBalanceAmount,
        BuyDate: row.BuyDate,
      };
      data.handleTypeList = Enumerable.from(that.productHandlerList).select(
        (item) => ({
          ID: item.ID,
          Name: item.Name,
          Employee: [],
        })
      );

      that.selectProduct.push(data);
    },

    // 储值卡选择
    savingCardChange: function (row) {
      var that = this;
      if (row.ValidBalance <= 0) {
        that.$message.error({
          message: "有效余额不足",
          duration: 2000,
        });
        return;
      }
      var data = {
        type: 2,
        ID: row.ID,
        SavingCardID: row.SavingCardID,
        Name: row.Name,
        Alias: row.Alias,
        effectiveGiftPrice: row.AllowRefundLargessBalance,
        effectivePrice: row.AllowRefundBalance,
        AllowRefundLargessBalance: row.AllowRefundLargessBalance,
        AllowRefundBalance: row.AllowRefundBalance,
        ValidDayName: row.ValidDayName,
        ValidBalance: row.ValidBalance,
        TotalBalance: row.TotalBalance,
        Balance: row.Balance,
        LargessBalance: row.LargessBalance,
        ArrearAmount: row.ArrearAmount,
        RefundAmount: row.RefundAmount,
        BuyDate: row.BuyDate,
        number: 1,
        handleTypeList: [],
      };
      that.savingCardHandlerList.forEach(function (item) {
        data.handleTypeList.push({
          ID: item.ID,
          Name: item.Name,
          Employee: [],
        });
      });
      that.selectSavingCard.push(data);
    },
    // 时效卡选择
    timeCardChange: function (row) {
      var that = this;
      if (
        row.CycleLimitAmount - row.ConsumeAmount <= 0 &&
        row.ConsumeCycle != 0 &&
        row.CycleLimitAmount != 0
      ) {
        that.$message.error({
          message: "周期内有效次数不足",
          duration: 2000,
        });
        return;
      }

      var data = {
        type: 4,
        handleTypeList: [],
        number: 1,
        ID: row.ID,
        TimeCardID: row.TimeCardID,
        Name: row.Name,
        Alias: row.Alias,
        Amount: row.Amount,
        effectivePrice: row.IsLargess
          ? 0
          : parseFloat(row.TotalAmount) - parseFloat(row.ArrearAmount),
        PricePreferentialAmount: 0,
        ValidDayName: row.ValidDayName,
        ValidBalance: row.ValidBalance,
        ConsumeCycle: row.ConsumeCycle,
        CycleLimitAmount: row.CycleLimitAmount,
        ConsumeCycleAmount: row.ConsumeCycleAmount,
        PerformanceTimes: row.PerformanceTimes,
        PerformanceBalance: row.PerformanceBalance,
        IsLargess: row.IsLargess,
        Price: row.Price,
        ArrearAmount: row.ArrearAmount,
        BuyDate: row.BuyDate,
        TotalAmount: row.TotalAmount,
        ConsumeAmount: row.ConsumeAmount,
        PerformanceBalanceAmount: row.PerformanceBalanceAmount,
      };

      data.handleTypeList = Enumerable.from(that.timeCardHandlerList).select(
        (item) => ({
          ID: item.ID,
          Name: item.Name,
          Employee: [],
        })
      );

      that.selectTimeCard.push(data);
    },
    // 通用次卡选择
    generalCardChange: function (row) {
      var that = this;
      if (row.Balance <= 0) {
        that.$message.error({
          message: "有效次数不足",
          duration: 2000,
        });
        return;
      }
      var data = {
        type: 5,
        allRefund: true,
        handleTypeList: [],
        number: row.Balance,
        RefundPrice: row.IsLargess == true ? 0 : row.PerformanceBalanceAmount,
        PricePreferentialAmount: 0,
        ID: row.ID,
        GeneralCardID: row.GeneralCardID,
        Name: row.Name,
        Alias: row.Alias,
        ValidDayName: row.ValidDayName,
        ValidBalance: row.ValidBalance,
        CardTimes: row.CardTimes,
        Balance: row.Balance,
        Price: row.Price,
        IsLargess: row.IsLargess,
        ArrearAmount: row.ArrearAmount,
        TotalAmount: row.TotalAmount,
        Amount: row.Amount,
        RefundAmount: row.RefundAmount,
        BuyDate: row.BuyDate,
        PerformanceBalanceAmount: row.PerformanceBalanceAmount,
      };
      data.handleTypeList = Enumerable.from(that.generalCardHandlerList).select(
        (item) => ({
          ID: item.ID,
          Name: item.Name,
          Employee: [],
        })
      );

      that.selectGeneralCard.push(data);
    },
    //提交申请
    submitPayClick() {
      var that = this;
      let project = [];
      let product = [];
      let packages = [];
      let generalCard = [];
      let savingCard = [];
      let timeCard = [];
      if (that.Checkout == false) {
        that.$message.error({
          message: "金额错误不允许结账",
          duration: 2000,
        });
        return;
      }
      if (that.payMethodTotalAmount != that.payInfo.PayAmount) {
        that.$message.error({
          message: "现金退款金额不正确",
          duration: 2000,
        });
        return;
      }
      if (
        that.savingCardDeductionTotalAmount.toFixed(2) !=
        parseFloat(
          that.payInfo.CardDeductionAmount +
            that.payInfo.CardDeductionLargessAmount
        ).toFixed(2)
      ) {
        that.$message.error({
          message: "卡抵扣退款金额不正确",
          duration: 2000,
        });
        return;
      }
      //项目
      that.selectProject.forEach((p) => {
        let projectTotal = 0;
        projectTotal += parseFloat(p.RefundPrice);
        let empList = [];
        p.handleTypeList.forEach((s) => {
          s.Employee.forEach((v) => {
            empList.push({
              HandlerID: v.SaleHandlerID,
              EmployeeID: v.EmployeeID,
              Scale: v.Discount,
            });
          });
        });
        project.push({
          Quantity: p.number,
          Price: p.Price,
          PricePreferentialAmount: p.PricePreferentialAmount,
          ProjectAccountID: p.ID,
          TotalAmount: projectTotal,
          RefundHandler: empList,
        });
      });
      //产品
      that.selectProduct.forEach((p) => {
        let productTotal = 0;
        productTotal += parseFloat(p.RefundPrice);
        let empList = [];
        p.handleTypeList.forEach((s) => {
          s.Employee.forEach((v) => {
            empList.push({
              HandlerID: v.SaleHandlerID,
              EmployeeID: v.EmployeeID,
              Scale: v.Discount,
            });
          });
        });
        product.push({
          Quantity: p.number,
          Price: p.Price,
          PricePreferentialAmount: p.PricePreferentialAmount,
          ProductAccountID: p.ID,
          TotalAmount: productTotal,
          RefundHandler: empList,
        });
      });
      // 套餐卡
      packages = Enumerable.from(that.selectPackageCard)
        .select((i) => {
          let packageItemTotalAmount = 0;
          let packageAllLargess = 0; //获取套餐卡赠送总价
          packageItemTotalAmount += Enumerable.from(i.Project).sum((j) =>
            parseFloat(j.effectivePrice)
          );
          packageItemTotalAmount += Enumerable.from(i.Product).sum((j) =>
            parseFloat(j.effectivePrice)
          );
          packageItemTotalAmount += Enumerable.from(i.SavingCard).sum((j) =>
            parseFloat(j.effectivePrice)
          );
          packageItemTotalAmount += Enumerable.from(i.TimeCard).sum((j) =>
            parseFloat(j.effectivePrice)
          );
          packageItemTotalAmount += Enumerable.from(i.GeneralCard).sum((j) =>
            parseFloat(j.genAmount)
          );

          packageAllLargess += Enumerable.from(i.SavingCard).sum((j) =>
            parseFloat(j.effectiveGiftPrice)
          );

          let PackageItem = {
            PackageCardAccountID: i.ID,
            Amount: packageItemTotalAmount,
            LargessAmount: packageAllLargess,
            Product: Enumerable.from(i.Product)
              .select((j) => ({
                Quantity: j.number,
                Price: j.Price,
                PricePreferentialAmount: j.PricePreferentialAmount,
                ProductAccountID: j.ID,
                TotalAmount: j.effectivePrice,
              }))
              .toArray(),
            Project: Enumerable.from(i.Project)
              .select((j) => ({
                ProjectAccountID: j.ID,
                TotalAmount: j.effectivePrice,
                Quantity: j.number,
                Price: j.Price,
                PricePreferentialAmount: j.PricePreferentialAmount,
              }))
              .toArray(),
            GeneralCard: Enumerable.from(i.GeneralCard)
              .select((j) => ({
                GeneralCardAccountID: j.ID,
                TotalAmount: j.genAmount,
                Quantity: j.number,
                Price: j.Price,
                PricePreferentialAmount: j.PricePreferentialAmount,
              }))
              .toArray(),
            TimeCard: Enumerable.from(i.TimeCard)
              .select((j) => ({
                Price: j.Price,
                PricePreferentialAmount: j.PricePreferentialAmount,
                TimeCardAccountID: j.ID,
                TotalAmount: j.effectivePrice,
              }))
              .toArray(),
            SavingCard: Enumerable.from(i.SavingCard)
              .select((j) => ({
                SavingCardAccountID: j.ID,
                Amount: j.effectivePrice,
                LargessAmount: j.effectiveGiftPrice,
              }))
              .toArray(),
            RefundHandler: [],
          };
          // 经手人
          i.handleTypeList.forEach((hand) => {
            hand.Employee.forEach((emp) => {
              PackageItem.RefundHandler.push({
                HandlerID: emp.SaleHandlerID,
                EmployeeID: emp.EmployeeID,
                Scale: emp.Discount,
              });
            });
          });

          return PackageItem;
        })
        .toArray();

      //通用次卡
      that.selectGeneralCard.forEach((p) => {
        let generalTotal = 0;
        generalTotal += parseFloat(p.RefundPrice);
        let empList = [];
        p.handleTypeList.forEach((s) => {
          s.Employee.forEach((v) => {
            empList.push({
              HandlerID: v.SaleHandlerID,
              EmployeeID: v.EmployeeID,
              Scale: v.Discount,
            });
          });
        });
        generalCard.push({
          Quantity: p.number,
          Price: p.Price,
          PricePreferentialAmount: p.PricePreferentialAmount,
          GeneralCardAccountID: p.ID,
          TotalAmount: generalTotal,
          RefundHandler: empList,
        });
      });
      //时效卡
      that.selectTimeCard.forEach((p) => {
        let timeCardTotal = 0;
        timeCardTotal += parseFloat(p.effectivePrice);

        let empList = [];
        p.handleTypeList.forEach((s) => {
          s.Employee.forEach((v) => {
            empList.push({
              HandlerID: v.SaleHandlerID,
              EmployeeID: v.EmployeeID,
              Scale: v.Discount,
            });
          });
        });
        timeCard.push({
          Quantity: p.number,
          Price: p.Price,
          PricePreferentialAmount: p.PricePreferentialAmount,
          TimeCardAccountID: p.ID,
          TotalAmount: timeCardTotal,
          RefundHandler: empList,
        });
      });
      //储值卡
      that.selectSavingCard.forEach((p) => {
        let savingCardTotal = 0;
        let savingCardTotalLargee = 0;
        savingCardTotal += parseFloat(p.effectivePrice);
        savingCardTotalLargee += parseFloat(p.effectiveGiftPrice);

        let empList = [];
        p.handleTypeList.forEach((s) => {
          s.Employee.forEach((v) => {
            empList.push({
              HandlerID: v.SaleHandlerID,
              EmployeeID: v.EmployeeID,
              Scale: v.Discount,
            });
          });
        });
        savingCard.push({
          SavingCardAccountID: p.ID,
          Amount: savingCardTotal,
          LargessAmount: savingCardTotalLargee,
          RefundHandler: empList,
        });
      });

      let PayMethod = Enumerable.from(that.payList)
        .where((i) => {
          return i.Amount && i.Amount != "";
        })
        .select((i) => ({
          PayMethodID: i.PayMethodID,
          Amount: i.Amount,
        }))
        .toArray();
      // that.payList.forEach((p) => {
      //   if (p.PayMethodID != "" && p.PayMethodID != null) {
      //     PayMethod.push({
      //       PayMethodID: p.PayMethodID,
      //       Amount: p.Amount,
      //     });
      //   }
      // });

      let SavingCardDeduction = Enumerable.from(
        that.payInfo.SavingCardDeduction
      )
        .select((i) => ({
          SavingCardAccountID: i.SavingCardAccountID,
          DeductionAmount: i.Amount,
          LargessDeductionAmount: i.LargessAmount,
        }))
        .toArray();

      // that.payInfo.SavingCardDeduction.forEach((p) => {

      //   SavingCardDeduction.push({
      //     SavingCardAccountID: p.SavingCardAccountID,
      //     DeductionAmount: p.Amount,
      //     LargessDeductionAmount: p.LargessAmount,
      //   });
      // });

      let params = {
        CustomerID: that.customerID,
        BillDate: that.getBillDate(),
        TotalAmount: that.payInfo.TotalAmount,
        PayAmount: that.payInfo.PayAmount,
        CardDeductionAmount: that.payInfo.CardDeductionAmount,
        CardDeductionLargessAmount: that.payInfo.CardDeductionLargessAmount,
        Remark: that.Remark,
        Project: project,
        Product: product,
        PackageCard: packages,
        GeneralCard: generalCard,
        TimeCard: timeCard,
        SavingCard: savingCard,
        PayMethod: PayMethod,
        SavingCardDeduction: SavingCardDeduction,
        PricePreferentialAmount: that.PricePreferentialAmount,
      };

      that.modalLoading = true;
      APIR.createApply(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: res.Message,
              duration: 2000,
            });
            that.dialogBill = false;
            that.IsSupplement = false;
            that.BillDate = null;

            // that.billOrderNumber = res.Data.BillID;
            // that.dialogSuccess = true;
            // that.refundTotalAmount_s = that.payInfo.TotalAmount
            // that.sucRemark = that.Remark;

            //清空选择的内容
            that.billClickClear();
            //刷新列表
            if (that.customerID != null && that.customerID != "") {
              that.getStockNetwork();
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(() => {
          that.modalLoading = false;
        });
    },
    /**  经手人事件  */
    // 经手人
    employeeHandleClick: function (type, row, item, index) {
      var that = this;
      that.tabHandle = "0";
      var emplayee = [];
      switch (type) {
        case 1:
          that.handlerList = that.projectHandlerList;
          break;
        case 2:
          that.handlerList = that.savingCardHandlerList;
          break;
        case 3:
          that.handlerList = that.timeCardHandlerList;
          break;
        case 4:
          that.handlerList = that.generalCardHandlerList;
          break;
        case 5:
          that.handlerList = that.packageCardHandlerList;
          break;
        case 6:
          that.handlerList = that.productHandlerList;
          break;
      }
      item.handleTypeList.forEach(function (hand) {
        hand.Employee.forEach(function (emp) {
          emplayee.push({ ID: emp.ID, Discount: emp.Discount });
        });
      });
      that.handlerList.forEach(function (handler) {
        handler.Employee.forEach(function (emp) {
          emp.Checked = false;
          emp.Discount = "";
          emplayee.forEach(function (i) {
            if (emp.ID == i.ID) {
              emp.Checked = true;
              emp.Discount = i.Discount;
            }
          });
        });
      });
      that.type = type;
      that.selectGoods = row;
      that.goodsIndex = index;
      that.dialogVisible = true;
    },
    // 百分比
    handlerPercentChange: function (row, item, name) {
      var that = this;
      var discount = 0;
      if (item.Discount != "") {
        item.Discount = parseFloat(item.Discount);
      }
      if (item.Discount > 100) {
        item.Discount = 100;
      }
      var employee = Enumerable.from(row)
        .where(function (i) {
          return i.Checked;
        })
        .toArray();
      employee.forEach(function (emp) {
        var Discount = emp.Discount;
        if (Discount == "") {
          Discount = 0;
        }
        discount = parseFloat(discount) + parseFloat(Discount);
      });
      if (parseFloat(discount) > 100) {
        item.Discount = 100 - (discount - item.Discount);
        that.$message.error("比例总和不能超过100%");
      }
      if (name == "套餐卡") {
        this.$forceUpdate();
      }
    },
    // 经手人确认选择
    submitHandleClick: function () {
      var that = this;
      var goodsHandlerList = JSON.parse(JSON.stringify(that.handlerList));
      goodsHandlerList.forEach(function (item) {
        item.Employee = Enumerable.from(item.Employee)
          .where(function (i) {
            return i.Checked;
          })
          .toArray();
      });
      that.selectGoods[that.goodsIndex].handleTypeList = goodsHandlerList;
      switch (that.type) {
        case 1:
          that.selectProject = that.selectGoods;
          break;
        case 2:
          that.selectSavingCard = that.selectGoods;
          break;
        case 3:
          that.selectTimeCard = that.selectGoods;
          break;
        case 4:
          that.selectGeneralCard = that.selectGoods;
          break;
        case 5:
          that.selectPackageCard = that.selectGoods;
          break;
        case 6:
          that.selectProduct = that.selectGoods;
          break;
      }
      that.dialogVisible = false;
    },
    // 删除经手人
    removeHandleClick: function (item, index, name) {
      item.Employee.splice(index, 1);
      if (name == "套餐卡") {
        this.$forceUpdate();
      }
    },
    // 删除项目
    removeClick: function (type, index) {
      var that = this;
      switch (type) {
        case 1:
          that.selectProject.splice(index, 1);
          break;
        case 2:
          that.selectSavingCard.splice(index, 1);
          break;
        case 3:
          that.selectTimeCard.splice(index, 1);
          break;
        case 4:
          that.selectGeneralCard.splice(index, 1);
          break;
        case 6:
          that.selectProduct.splice(index, 1);
          break;
      }
    },
    // 经手人选择
    handlerCheckedChange: function (row, item) {
      var that = this;
      var discount = 0;
      var employee = Enumerable.from(row)
        .where(function (i) {
          return i.Checked;
        })
        .toArray();
      employee.forEach(function (emp) {
        var Discount = emp.Discount;
        if (Discount == "") {
          Discount = 0;
        }
        discount = parseFloat(discount) + parseFloat(Discount);
      });
      if (parseFloat(discount) > 100) {
        item.Discount = 100 - (discount - item.Discount);
        that.$message.error("比例总和不能超过100%");
      }
      if (!item.Checked) {
        item.Discount = "";
      } else {
        if (item.Discount == "") {
          if (discount > 100) {
            item.Discount = 0;
          } else {
            item.Discount = 100 - discount;
          }
        }
      }
    },

    //会员修改的删除事件
    huiyuanCheck() {
      var that = this;
      that.productList = [];
      that.savingCardList = [];
      that.packageCardList = [];
      that.timeCardList = [];
      that.generalCardList = [];
      that.projectList = [];
      that.packageCardDetailList = "";
      that.selectPackageCard = [];
      that.selectGeneralCard = [];
      that.selectProduct = [];
      that.selectProject = [];
      that.selectTimeCard = [];
      that.selectSavingCard = [];
      that.Remark = "";
    },

    //退回储值卡中价格改变
    paySavingPriceChange(item) {
      if (parseFloat(item.LargessAmount) > item.oldLargessAmount) {
        item.LargessAmount = item.oldLargessAmount;
      }
      if (parseFloat(item.Amount) > item.oldAmount) {
        item.Amount = item.oldAmount;
      }
    },
    //清空
    billClickClear() {
      var that = this;
      that.selectPackageCard = [];
      that.selectGeneralCard = [];
      that.selectProduct = [];
      that.selectProject = [];
      that.selectTimeCard = [];
      that.selectSavingCard = [];
      that.Remark = "";
    },
    //结账
    billClick() {
      var that = this;
      if (
        (that.selectProject.length == 0) &
        (that.selectSavingCard.length == 0) &
        (that.selectTimeCard.length == 0) &
        (that.selectGeneralCard.length == 0) &
        (that.selectPackageCard.length == 0) &
        (that.selectProduct.length == 0)
      ) {
        that.$message.error("请选择退款择商品！");
        return;
      }
      if (that.BillDate == "" || that.BillDate == null) {
        if (that.IsSupplement) {
          that.$message.error("请选择开单时间！");
          return;
        }
        that.BillDate = date.formatDate.format(
          new Date(),
          "YYYY-MM-DD hh:mm:ss"
        );
      }

      if (that.customerID == null) {
        that.$message.error("请选择顾客！");
        return;
      }

      that.payList = [{ PayMethodID: "", Amount: "", price: 0 }];

      that.savingDeductionPriceAll();
      // that.payAmountData();
    },
    //获取退存量 支付 方式
    savingDeductionPriceAll() {
      var that = this;
      let project = [];
      let product = [];
      let packages = [];
      let generalCard = [];
      let savingCard = [];
      let timeCard = [];
      //项目
      that.selectProject.forEach((p) => {
        let projectTotal = 0;
        projectTotal += parseFloat(p.RefundPrice);
        project.push({
          ProjectAccountID: p.ID,
          TotalAmount: projectTotal,
        });
      });
      //产品
      that.selectProduct.forEach((p) => {
        let productTotal = 0;
        productTotal += parseFloat(p.RefundPrice);
        product.push({
          ProductAccountID: p.ID,
          TotalAmount: productTotal,
        });
      });

      packages = Enumerable.from(that.selectPackageCard)
        .select((i) => {
          let packageItemTotalAmount = 0;
          let packageAllLargess = 0; //获取套餐卡赠送总价
          packageItemTotalAmount += Enumerable.from(i.Project).sum((j) =>
            parseFloat(j.effectivePrice)
          );
          packageItemTotalAmount += Enumerable.from(i.Product).sum((j) =>
            parseFloat(j.effectivePrice)
          );
          packageItemTotalAmount += Enumerable.from(i.SavingCard).sum((j) =>
            parseFloat(j.effectivePrice)
          );
          packageItemTotalAmount += Enumerable.from(i.TimeCard).sum((j) =>
            parseFloat(j.effectivePrice)
          );
          packageItemTotalAmount += Enumerable.from(i.GeneralCard).sum((j) =>
            parseFloat(j.genAmount)
          );

          packageAllLargess += Enumerable.from(i.SavingCard).sum((j) =>
            parseFloat(j.effectiveGiftPrice)
          );

          let PackageItem = {
            PackageCardAccountID: i.ID,
            Amount: packageItemTotalAmount,
            LargessAmount: packageAllLargess,
            Product: Enumerable.from(i.Product)
              .select((j) => ({
                ProductAccountID: j.ID,
                TotalAmount: j.effectivePrice,
              }))
              .toArray(),
            Project: Enumerable.from(i.Project)
              .select((j) => ({
                ProjectAccountID: j.ID,
                TotalAmount: j.effectivePrice,
              }))
              .toArray(),
            GeneralCard: Enumerable.from(i.GeneralCard)
              .select((j) => ({
                GeneralCardAccountID: j.ID,
                TotalAmount: j.genAmount,
              }))
              .toArray(),
            TimeCard: Enumerable.from(i.TimeCard)
              .select((j) => ({
                TimeCardAccountID: j.ID,
                TotalAmount: j.effectivePrice,
              }))
              .toArray(),
            SavingCard: Enumerable.from(i.SavingCard)
              .select((j) => ({
                SavingCardAccountID: j.ID,
                Amount: j.effectivePrice,
                LargessAmount: j.effectiveGiftPrice,
              }))
              .toArray(),
          };

          return PackageItem;
        })
        .toArray();

      //通用次卡
      that.selectGeneralCard.forEach((p) => {
        let generalTotal = 0;
        generalTotal += parseFloat(p.RefundPrice);
        generalCard.push({
          GeneralCardAccountID: p.ID,
          TotalAmount: generalTotal,
        });
      });
      //时效卡
      that.selectTimeCard.forEach((p) => {
        let timeCardTotal = 0;
        timeCardTotal += parseFloat(p.effectivePrice);
        timeCard.push({
          TimeCardAccountID: p.ID,
          TotalAmount: timeCardTotal,
        });
      });
      //储值卡
      that.selectSavingCard.forEach((p) => {
        let savingCardTotal = 0;
        let savingCardTotalLargee = 0;
        savingCardTotal += parseFloat(p.effectivePrice);
        savingCardTotalLargee += parseFloat(p.effectiveGiftPrice);
        savingCard.push({
          SavingCardAccountID: p.ID,
          Amount: savingCardTotal,
          LargessAmount: savingCardTotalLargee,
        });
      });
      let params = {
        Project: project,
        Product: product,
        GeneralCard: generalCard,
        SavingCard: savingCard,
        TimeCard: timeCard,
        PackageCard: packages,
        PricePreferentialAmount: that.PricePreferentialAmount,
      };
      that.createRefundLoading = true;
      APIR.getPay(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.dialogBill = true;
            that.Checkout = true;
            that.PayPrice = res.Data.PayAmount;
            res.Data.SavingCardDeduction.forEach((p) => {
              p.oldAmount = p.Amount;
              p.oldLargessAmount = p.LargessAmount;
            });
            that.payInfo = res.Data;
            if (that.payInfo.PayMethod.length == 0) {
              that.payList = [{ PayMethodID: "", Amount: "", price: 0 }];
            } else {
              that.payList = [];
              that.payInfo.PayMethod.forEach((payM) => {
                that.payList.push({
                  PayMethodID: payM.PayMethodID,
                  Amount: payM.Amount,
                  Name: payM.Name,
                  price: payM.Amount,
                });
              });
            }
            that.paymentAmountData();
          } else {
            that.payInfo = {};
            that.Checkout = false;
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(() => {
          that.createRefundLoading = false;
        });
    },

    /**  网络请求  */
    // 项目
    projectData: function () {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        Name: that.goodsName,
      };
      APIR.getProjectAccount(params).then((res) => {
        if (res.StateCode == 200) {
          that.projectList = res.Data;
          if (that.tabPane == "" && that.projectList.length > 0) {
            that.tabPane = "0";
          }
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 储值卡
    savingCardData: function () {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        Name: that.goodsName,
      };
      APIR.getSavingCardAccount(params).then((res) => {
        if (res.StateCode == 200) {
          that.savingCardList = res.Data;
          if (that.tabPane == "" && that.savingCardList.length > 0) {
            that.tabPane = "1";
          }
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 时效卡
    timeCardData: function () {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        Name: that.goodsName,
      };
      APIR.getTimeCardAccount(params).then((res) => {
        if (res.StateCode == 200) {
          that.timeCardList = res.Data;
          if (that.tabPane == "" && that.timeCardList.length > 0) {
            that.tabPane = "2";
          }
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 通用次卡
    generalCardData: function () {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        Name: that.goodsName,
      };
      APIR.getGeneralCardAccount(params).then((res) => {
        if (res.StateCode == 200) {
          that.generalCardList = res.Data;
          if (that.tabPane == "" && that.generalCardList.length > 0) {
            that.tabPane = "3";
          }
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 套餐卡
    packageCardData: function () {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        Name: that.goodsName,
      };
      APIR.getPackageCardAccount(params).then((res) => {
        if (res.StateCode == 200) {
          that.packageCardList = res.Data;
          if (that.tabPane == "" && that.packageCardList.length > 0) {
            that.tabPane = "4";
          }
          if (that.packageCardList.length > 0) {
            that.currentPackCategoryIndex = 0;
            that.currentSelectPackageItem = that.packageCardList[0];
            that.getPackageCardAccountDetails();
          }
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 套餐卡明细
    getPackageCardAccountDetails: function (packageAllRefund) {
      var that = this;
      var params = {
        PackageCardAccountID: that.currentSelectPackageItem.ID,
      };
      that.packageDetailLoading = true;
      APIR.getPackageCardAccountDetails(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageCardDetailList = res.Data;
            if (packageAllRefund) {
              let falg = false;
              // 0项目 1 储值卡 2时效卡 3 通用次卡 4产品
              res.Data.Project.forEach((project) => {
                if (project.RefundBalance == 0) {
                  falg = true;
                  that.selectPackageDetailItem(project, 0);
                }
              });
              res.Data.SavingCard.forEach((savingCard) => {
                if (savingCard.ValidBalance > 0) {
                  falg = true;
                  that.selectPackageDetailItem(savingCard, 1);
                }
              });
              res.Data.TimeCard.forEach((timeCard) => {
                falg = true;
                that.selectPackageDetailItem(timeCard, 2);
              });
              res.Data.GeneralCard.forEach((general) => {
                if (general.RefundAmount == 0) {
                  falg = true;
                  that.selectPackageDetailItem(general, 3);
                }
              });
              res.Data.Product.forEach((produc) => {
                if (produc.RefundBalance == 0) {
                  falg = true;
                  that.selectPackageDetailItem(produc, 4);
                }
              });

              if (!falg) {
                that.$message.error({
                  message: "套餐卡明细均在退款处理中，请点击明细查看。",
                  duration: 2000,
                });
              }
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(() => {
          that.packageDetailLoading = false;
          that.loading = false;
        });
    },
    // 产品
    productData: function () {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        Name: that.goodsName,
      };
      APIR.getProductAccount(params).then((res) => {
        if (res.StateCode == 200) {
          that.productList = res.Data;
          if (that.tabPane == "" && that.productList.length > 0) {
            that.tabPane = "5";
          }
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },

    // 项目经手人
    projectHandlerData: function () {
      var that = this;
      API.getProjectHandler().then((res) => {
        if (res.StateCode == 200) {
          that.projectHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 产品经手人
    productHandlerData: function () {
      var that = this;
      API.getProductHandler().then((res) => {
        if (res.StateCode == 200) {
          that.productHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 通用次卡经手人
    generalHandlerData: function () {
      var that = this;
      API.getGeneralCardHandler().then((res) => {
        if (res.StateCode == 200) {
          that.generalCardHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 时效卡经手人
    timeCardHandlerData: function () {
      var that = this;
      API.getTimeCardHandler().then((res) => {
        if (res.StateCode == 200) {
          that.timeCardHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 储值卡经手人
    savingCardHandlerData: function () {
      var that = this;
      API.getSavingCardHandler().then((res) => {
        if (res.StateCode == 200) {
          that.savingCardHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 套餐卡经手人
    packageCardHandlerData: function () {
      var that = this;
      API.getPackageCardHandler().then((res) => {
        if (res.StateCode == 200) {
          that.packageCardHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },

    //套餐卡下退款
    packageInputPriceChange(item, type) {
      var that = this;
      switch (type) {
        case 1: // 储值卡本金
          if (
            parseFloat(item.effectivePrice) >
            parseFloat(item.AllowRefundBalance)
          ) {
            that.$message.error("可用余额不足");
            item.effectivePrice = parseFloat(item.AllowRefundBalance).toFixed(
              2
            );
          }
          item.effectiveGiftPrice = parseFloat(
            (parseFloat(item.AllowRefundLargessBalance) /
              parseFloat(item.AllowRefundBalance)) *
              item.effectivePrice
          ).toFixed(2);

          break;
        case 2: // 储值卡赠额
          if (
            parseFloat(item.effectiveGiftPrice) >
            parseFloat(item.AllowRefundLargessBalance)
          ) {
            that.$message.error("有效赠额不足");
            item.effectiveGiftPrice = parseFloat(
              item.AllowRefundLargessBalance
            ).toFixed(2);
          }

          item.effectivePrice = parseFloat(
            (parseFloat(item.AllowRefundBalance) /
              parseFloat(item.AllowRefundLargessBalance)) *
              item.effectiveGiftPrice
          ).toFixed(2);

          break;
        case 3:
          var total_11 = parseFloat(item.number * item.Amount).toFixed(2);
          if (parseFloat(item.genAmount) > total_11) {
            that.$message.error("退款金额不能大于有效次数 * 参考单价");
            item.genAmount = total_11;
          }
          item.PricePreferentialAmount =
            parseFloat(total_11) - parseFloat(item.genAmount);

          break;
        case 4:
          if (parseFloat(item.effectivePrice) > item.TotalAmount) {
            that.$message.error("退款金额不能大于购买金额");
            item.effectivePrice = item.TotalAmount;
          }
          item.PricePreferentialAmount =
            parseFloat(item.TotalAmount) - parseFloat(item.effectivePrice);

          break;
        case 5:
          if (
            parseFloat(item.effectivePrice) >
            parseFloat(item.Amount * item.number)
          ) {
            that.$message.error("退款金额不能大于退款次数 * 参考单价");
            item.effectivePrice = parseFloat(item.Amount * item.number);
          }
          item.PricePreferentialAmount =
            parseFloat(item.Amount * item.number) -
            parseFloat(item.effectivePrice);

          break;
      }
    },

    // 退款
    arrearChange: function (item, type) {
      var that = this;
      switch (type) {
        case 1:
          if (
            parseFloat(item.RefundPrice) >
            parseFloat(item.Amount) * parseFloat(item.number)
          ) {
            that.$message.error("退款金额不能大于有效次数 * 参考单价");
            item.RefundPrice =
              parseFloat(item.Amount) * parseFloat(item.number);
          }
          item.PricePreferentialAmount =
            parseFloat(item.Amount) * parseFloat(item.number) -
            parseFloat(item.RefundPrice);
          break;
        case 2: // 储值卡本金
          console.log(
            "🚀 ~ file: refundBillCreate.vue ~ line 5810 ~ item",
            item
          );
          if (item.effectivePrice > item.AllowRefundBalance) {
            that.$message.error("可用余额不足");
            item.effectivePrice = parseFloat(item.AllowRefundBalance).toFixed(
              2
            );
          }
          item.effectiveGiftPrice = parseFloat(
            (parseFloat(item.AllowRefundLargessBalance) /
              parseFloat(item.AllowRefundBalance)) *
              parseFloat(item.effectivePrice)
          ).toFixed(2);

          break;
        case 3: // 储值卡赠额
          if (item.effectiveGiftPrice > item.AllowRefundLargessBalance) {
            that.$message.error("有效赠额不足");
            item.effectiveGiftPrice = parseFloat(
              item.AllowRefundLargessBalance
            ).toFixed(2);
          }
          // item.effectivePrice = parseFloat(parseFloat(item.AllowRefundBalance) / parseFloat(item.AllowRefundLargessBalance) * parseFloat(item.effectiveGiftPrice)).toFixed(2);

          break;
        case 4:
          if (
            parseFloat(item.effectivePrice) >
            parseFloat(item.TotalAmount) - parseFloat(item.ArrearAmount)
          ) {
            that.$message.error("退款金额不能大于购买金额");
            item.effectivePrice = parseFloat(
              parseFloat(item.TotalAmount) - parseFloat(item.ArrearAmount)
            ).toFixed(2);
          }
          item.PricePreferentialAmount =
            parseFloat(item.TotalAmount) - parseFloat(item.effectivePrice);
          break;
        case 5:
          if (
            parseFloat(item.RefundPrice) >
            parseFloat(item.Amount) * parseFloat(item.number)
          ) {
            that.$message.error("退款金额不能大于有效次数 * 参考单价");
            item.RefundPrice = (
              parseFloat(item.Amount) * parseFloat(item.number)
            ).toFixed(2);
          }
          item.PricePreferentialAmount =
            parseFloat(item.Amount) * parseFloat(item.number) -
            parseFloat(item.RefundPrice);
          break;
      }
    },

    /** 获取全部 支付方式  */
    getAllPayMethodData: function () {
      var that = this;
      that.loading = true;
      APIR.getSalePayMethod()
        .then((res) => {
          if (res.StateCode == 200) {
            that.payTypeList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /** 打印小票   */
    printOrderReceipt() {
      var that = this;
      that.getOrderDetail();
      that.getReceiptConfig();
    },

    /** 获取订单详情 */
    getOrderDetail() {
      var that = this;
      that.printLoading = true;

      var params = {
        SaleBillID: that.billOrderNumber,
      };

      orderAPI
        .getOrderDetail(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.saleOrderDetail = res.Data;
            that.arrearCashierReceiptDialogVisible = true;
          }
        })
        .finally(function () {
          that.printLoading = false;
        });
    },
    /**  获取打印内容  */
    printRefundSaleBillContent() {
      let that = this;
      let params = {
        SaleBillID: that.billOrderNumber,
      };
      cashierAPI
        .printRefundSaleBillContent(params)
        .then((res) => {
          if (res.StateCode == 200) {
            for (let index = 0; index < res.Data.copies; index++) {
              printReceipt.doActionPrint(res.Data.printDocuments, (request) => {
                socket.send(JSON.stringify(request));
              });
            }
          }
        })
        .finally(() => {});
    },

    /**  获取小票配置信息  */
    getReceiptConfig() {
      var that = this;
      cashierAPI
        .getReceiptConfigBill()
        .then((res) => {
          if (res.StateCode == 200) {
            that.cashierReceipt = res.Data;
          }
        })
        .finally(() => {});
    },

    // 姓名隐藏
    formatName(name) {
      return printReceipt.hiddenName(name);
    },
    // 手机号隐藏
    formatPhone(phone) {
      return printReceipt.hiddenPhone(phone);
    },
  },
  mounted() {
    var that = this;
    socket = printReceipt.getSocket((res) => {
      if (res.status == "success") {
        that.$message.success({
          message: "打印成功",
          duration: 2000,
        });
        that.arrearCashierReceiptDialogVisible = false;
      }
    });
    if (localStorage.getItem("access-user")) {
      that.userName = JSON.parse(
        localStorage.getItem("access-user")
      ).EmployeeName;
    }
    if (localStorage.getItem("entityName")) {
      that.entityName = JSON.parse(localStorage.getItem("entityName"));
    }

    that.employeeData();
    that.CustomerSourceData();
    that.CustomerLevelData();
    // 获取经手人
    that.projectHandlerData();
    that.productHandlerData();
    that.generalHandlerData();
    that.timeCardHandlerData();
    that.savingCardHandlerData();
    that.packageCardHandlerData();
    // 获取全部的支付方式
    that.getAllPayMethodData();
  },
};
</script>

<style lang="scss">
.RefundBillCreate {
  height: 100%;
  background-color: #ffffff;
  .refundBill_content {
    height: calc(100% - 62px);
    font-size: 13px;
    color: #666666;
    .project_left {
      border-right: 1px solid #eee;
      height: 100%;
      color: #303133;

      .treatGoods {
        .goods-item {
          line-height: 36px;
          font-size: 12px;
          margin-left: 10px;
          margin-right: 5px;
        }
        .goods-lable {
          color: #606266;
        }

        .el-card__header {
          padding: 10px;
          background-color: #f5f7fa;
        }

        height: calc(100% - 62px);
        .el-tabs__header {
          margin: 0;
          .el-tabs__nav-scroll {
            margin-left: 15px;
          }
        }
        .el-tabs__content {
          height: calc(100% - 40px);
          .el-tab-pane {
            height: 100%;
            .el-scrollbar_height {
              height: 100%;
              .el-scrollbar__wrap {
                overflow-x: hidden;
              }
            }
            .col_border {
              border-right: 1px solid #eee;
              padding: 5px;
              color: #666;
              font-size: 14px;
            }
            .category_project {
              height: 100%;
              .category {
                height: 100%;
                .el-scrollbar_height {
                  height: calc(100% - 50px);
                }
              }

              .project {
                height: 100%;
                overflow: auto;
                .producct_list {
                  height: 100%;
                  .el-scrollbar_height {
                    height: calc(100% - 50px);
                  }
                }
              }
            }
          }
        }
      }
    }

    .project_right {
      height: 100%;
      .el-icon-sort {
        color: #666;
        font-size: 20px;
        transform: rotate(90deg);
      }
      .el-main {
        padding: 0;
        .row_header {
          background-color: #ecf8ff;
          padding: 10px;
        }
        .employee_num {
          width: 90px;
          .el-input-group__append {
            padding: 0 10px;
          }
        }

        .el-scrollbar_height {
          height: 100%;
          .el-scrollbar__wrap {
            overflow-x: hidden;
          }
        }
        .el-form-item__label {
          font-size: 13px !important;
        }
        .back_project_col {
          background-color: #fafafa;
        }
      }
      .el-footer {
        height: initial !important;
        padding: 10px;
      }
    }
  }

  .el-scrollbar_height {
    height: 100%;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .valueCard_project {
    height: 430px;
    .category {
      height: 100%;
      .row_header {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 600;
        color: #333333;
        height: 45px;
        width: 100%;
        // border-bottom: 1px solid #eeeeee;
        // border-right: 1px solid #eeeeee;
        background-color: #ecf8ff;
      }
      .el-scrollbar_height {
        height: 100%;
      }

      .category_select {
        color: #039be5;
      }
    }

    .popover-package {
      padding: 0px;
      .goods-item {
        line-height: 36px;
        font-size: 12px;
        margin-left: 20px;
        margin-right: 5px;
      }
      .goods-lable {
        color: #606266;
      }

      .el-card__header {
        padding: 10px 20px !important;
        background-color: #f5f7fa !important;
      }
    }
  }
  .packageDetailDialogClass {
    // .treatGoods {
    .goods-item {
      line-height: 36px;
      font-size: 12px;
      margin-left: 10px;
      margin-right: 5px;
    }
    .goods-lable {
      color: #606266;
    }
    .el-card__header {
      padding: 10px;
      background-color: #f5f7fa;
    }
    // }
    // .popoveEl_scrollbar_height {
    //   max-height: 50vh;
    //   .el-scrollbar__wrap {
    //     overflow-x: hidden;
    //   }
    // }
  }
}

.customer-autocomplete {
  li {
    line-height: normal;
    padding: 7px;
    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .info {
      font-size: 12px;
      color: #b4b4b4;
    }
    .highlighted .info {
      color: #ddd;
    }
  }
}
</style>


